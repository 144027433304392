import React, { useMemo, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import * as Ant from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { createEditor } from "slate";
import { withReact, Slate } from "slate-react";
import { withHistory } from "slate-history";
import _ from "lodash";

import { FontSize, Color } from "./Widget";
import RichTextEditor from "./RichTextEditor";
import Matrix from "./Matrix";
import { QuestionPrefix, ErrorText } from "./LiveQuestion.common";
import { OptionRows } from "./LiveQuestion.option";
import VideoIframe from "./VideoIframe";

function SingleChoiceDisplay(props) {
  const [layoutType, setLayoutType] = useState("desktop");
  useEffect(() =>
    window.innerWidth >= 824
      ? setLayoutType("desktop")
      : setLayoutType("mobile")
  );
  return (
    <div>
      <QuestionPrefix {...props} />
      {layoutType === "desktop" ? (
        <OptionRows
          {...props}
          cols={props.question.advanced?.display?.[layoutType] || 1}
        />
      ) : (
        <OptionRows
          {...props}
          cols={props.question.advanced?.display?.[layoutType] >= 2 ? 2 : 1}
        />
      )}
    </div>
  );
}
/**
 * 填寫介面：複選題介面呈現
 * @param {*} props
 * @returns
 */
function MultiChoiceDisplay(props) {
  const [layoutType, setLayoutType] = useState("desktop");
  useEffect(() =>
    window.innerWidth >= 824
      ? setLayoutType("desktop")
      : setLayoutType("mobile")
  );

  return (
    <div>
      <QuestionPrefix {...props} />
      <OptionRows
        {...props}
        cols={props.question.advanced?.display?.[layoutType] || 1}
      />
    </div>
  );
}

function MatrixDisplay(props) {
  const [isDesktop, setIsDesktop] = useState("desktop");

  useEffect(() =>
    window.innerWidth >= 824 ? setIsDesktop(true) : setIsDesktop(false)
  );

  const inputProps = !props.answer
    ? {}
    : {
        isCellChecked: ({ row, col }) => {
          const answerArr = Array.isArray(props.answer?.answer)
            ? props.answer.answer
            : [];
          return (
            answerArr.findIndex(([x, y]) => {
              return x === row && y === col;
            }) > -1
          );
        },
        onCellCheck: ({ row, col }) => {
          const answerArr = Array.isArray(props.answer?.answer)
            ? props.answer.answer
            : [];
          const idx = answerArr.findIndex(([x, y]) => {
            return x === row; // note: one answer per row
          });
          if (idx > -1) {
            answerArr.splice(idx, 1);
            answerArr.push([row, col]);
          } else {
            answerArr.push([row, col]);
          }
          props.updateAnswer(props.questionIdx, {
            answer: answerArr,
          });
        },
      };

  return (
    <div>
      <QuestionPrefix {...props} />
      <Matrix
        {...props}
        isPreview={true}
        isDesktop={isDesktop}
        isOptionDisplayId={props.survey.optionIdxDisplay}
        rows={props.question.rows || ["---", "---"]}
        cols={props.question.cols || ["---", "---"]}
        setRows={() => 0}
        setCols={() => 0}
        {...inputProps}
        readOnly={true}
        preview
      />
    </div>
  );
}

function BasicInfoDisplay(props) {
  const inputProps = !props.answer
    ? {}
    : {
        value: props.answer?.answer,
        onChange: e => {
          props.updateAnswer(props.questionIdx, {
            answer: e.target.value,
          });
        },
      };
  const Placeholder = {
    hn: "請輸入HN後面的數字，例12345678",
    md: "請輸入MD後面的數字，例12345678",
    id: "F123456789",
    email: "survey@cht.com",
    phone: "0911234567",
  };
  const errorTextMap = {
    hn: "必須為純數字",
    md: "必須為純數字",
    id: "開頭必須為英文＋9位數字",
    email: "Email 格式錯誤",
    phone: "須輸入 09 開頭的手機號碼共 10 碼",
  };

  const [validate, setValidate] = useState(false);
  const extraStyle = validate ? { borderColor: Color.Red } : {};
  const extraProps = {
    suffix: (
      <Ant.Tooltip>
        {validate ? (
          <ExclamationCircleFilled style={{ color: Color.Red }} size={14} />
        ) : (
          ""
        )}
      </Ant.Tooltip>
    ),
  };

  useEffect(() => {
    if (props.answer.answer !== null) {
      setValidate(!props.question.validate(props.answer));
    }
  }, [props.question, props.answer]);

  useEffect(() => {
    setValidate(
      props.validate && props.question.required && !props.answer?.answer
    );
  }, [props.validate]);

  return (
    <div className="fill-input">
      <QuestionPrefix {...props} />
      <div className="input-wrapper">
        <Ant.Input
          placeholder={Placeholder[props.question.infoType] || ""}
          style={{ ...extraStyle }}
          {...extraProps}
          {...inputProps}
          className={validate ? "has-error" : ""}
        />
      </div>
      {validate && props.answer.answer ? (
        <div className="error-text">
          <ErrorText>
            {errorTextMap[props.question.infoType] || "格式錯誤"}
          </ErrorText>
        </div>
      ) : (
        <div
          style={{
            height: 32,
            marginBottom: 16,
          }}
        />
      )}
    </div>
  );
}

/**
 * 預覽畫面:填空題
 * @param {*} props
 * @returns
 */
function FillInDisplay(props) {
  const inputProps = !props.answer
    ? {}
    : {
        value: props.answer?.answer,
        onChange: e => {
          props.updateAnswer(props.questionIdx, {
            answer: e.target.value,
          });
        },
      };

  const [validate, setValidate] = useState(false);
  const errorMap = {
    none: "不能為空",
    text: `字數須介於 ${props.question.validateAnswer.from} - ${props.question.validateAnswer.to} 字之間`,
    number: `須為整數。在 ${props.question.validateAnswer.from} - ${props.question.validateAnswer.to} 之間。`,
  };
  const extraStyle = validate ? { borderColor: Color.Red } : {};
  const extraProps = {
    suffix: (
      <Ant.Tooltip>
        {validate ? (
          <ExclamationCircleFilled style={{ color: Color.Red }} size={14} />
        ) : (
          ""
        )}
      </Ant.Tooltip>
    ),
  };

  useEffect(() => {
    if (props.answer.answer !== null) {
      setValidate(!props.question.validate(props.answer));
    }
  }, [props.question, props.answer]);

  useEffect(() => {
    setValidate(
      props.validate && props.question.required && !props.answer?.answer
    );
  }, [props.validate]);

  return (
    <div className="fill-input">
      <QuestionPrefix {...props} />
      <Ant.Input.TextArea
        autoSize
        placeholder={props.question.answerPlaceholder || ""}
        maxLength={props.question.validateAnswer?.type === "none" ? 100 : null}
        style={{ ...extraStyle }}
        {...extraProps}
        {...inputProps}
        className={validate ? "has-error" : ""}
      />
      {validate ? (
        <div className="error-text">
          <ErrorText>
            {errorMap[props.question.validateAnswer?.type || "none"]}
          </ErrorText>
        </div>
      ) : (
        <div style={{ height: 32, marginBottom: 16 }} />
      )}
    </div>
  );
}

function VideoDisplay(props) {
  return (
    <div className="iframe-wrapper">
      <VideoIframe
        title={`YouTube Player`}
        src={props.question.url}
        // width="100%"
        frameBorder="0"
        allowFullScreen={true}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></VideoIframe>
    </div>
  );
}

function ImageDisplay(props) {
  return (
    <div>
      <img
        src={props.question.url}
        onError={e => {
          e.target.src = "/images/image-placeholder.png";
          e.target.onError = null;
        }}
        style={{
          width: "100%",
          objectFit: "contain",
        }}
        alt="illustration"
      />
    </div>
  );
}

function NumericalDisplay(props) {
  const [layoutType, setLayoutType] = useState("desktop");
  let { numericalStyle, numericalTotal, decimalPlace } = props.question;
  const useSlider = numericalStyle == 0;
  numericalTotal = numericalTotal ?? 0;
  decimalPlace = decimalPlace ? decimalPlace : 0;
  const answers = props.answer?.answer ? props.answer.answer : [];
  const systemTheme = props.survey?._data._themeProps.Theme ?? {};

  // 計算總和
  let total = 0;
  answers.forEach(element => {
    let { value } = element;
    value = value ? parseFloat(value) : 0;
    total += value;
    total = _.round(total, 4);
  });

  // 計算 設定總和直 與 答案總和值 之差

  let sum = _.add(numericalTotal, total * -1);
  sum = _.round(sum, 4);

  useEffect(() =>
    window.innerWidth >= 824
      ? setLayoutType("desktop")
      : setLayoutType("mobile")
  );

  return (
    <NumericalDisplayWrapper
      className="numerical-display"
      useSlider={useSlider}
      theme={systemTheme}
    >
      <QuestionPrefix {...props} />
      {answers.length !== 0 && (
        <div className="total-text">
          {/* 
        如果沒答案(answers.length == 0)，則不顯示
        如果有答案(answers.length > 0)，判斷差值(sum)
        如果沒有差值(sum==0)，則不顯示"需XX"字樣
        如果差值為正(sum > 0)，顯示"需增加"字樣
        如果差值為負(sum < 0)，顯示"需減少"字樣 
        */}
          {answers.length > 0 ? (
            <>
              <a className="success">目前合計為 {total}</a>
              {sum != 0 ? (
                sum > 0 ? (
                  <>
                    <a className="success">，</a>
                    <a className="error">需增加 {sum}</a>
                  </>
                ) : (
                  <>
                    <a className="success">，</a>
                    <a className="error">需減少 {sum * -1}</a>
                  </>
                )
              ) : null}
            </>
          ) : null}
        </div>
      )}
      <OptionRows
        {...props}
        cols={props.question.advanced?.display?.[layoutType] || 1}
      />
    </NumericalDisplayWrapper>
  );
}
/**
 * 問題內容呈現
 * @param {*} props
 * @returns
 */
function QuestionDisplayContent(props) {
  const systemTheme = props.survey?._data._themeProps.Theme ?? {};
  return (
    <ContentWrapper
      numerical={props.question.type === "NUMERICAL"}
      useSlider={props.question.numericalStyle == 0}
    >
      {props.question.type === "SINGLE_CHOICE" && (
        <SingleChoiceDisplay {...props} type={props.question.type} />
      )}
      {props.question.type === "MULTIPLE_CHOICE" && (
        <MultiChoiceDisplay {...props} type={props.question.type} />
      )}
      {props.question.type === "MATRIX" && (
        <MatrixDisplay {...props} type={props.question.type} />
      )}
      {props.question.type === "BASIC_INFO" && (
        <BasicInfoDisplay {...props} type={props.question.type} />
      )}
      {props.question.type === "VIDEO" && (
        <VideoDisplay {...props} type={props.question.type} />
      )}
      {props.question.type === "IMAGE" && (
        <ImageDisplay {...props} type={props.question.type} />
      )}
      {props.question.type === "TEXT" && (
        <RichTextEditor
          readOnly={true}
          type={props.question.type}
          theme={systemTheme}
        />
      )}
      {props.question.type === "FILL_IN" && (
        <FillInDisplay {...props} type={props.question.type} />
      )}
      {props.question.type === "NUMERICAL" && <NumericalDisplay {...props} />}
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  width: 100%;

  .fill-input {
    textarea.ant-input,
    .ant-input-affix-wrapper,
    .ant-input-number {
      line-height: 32px;
      border-radius: 4px;
      border: solid 1px rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      font-size: ${FontSize.head}px;
      color: ${Color.Black_65};
      width: 100%;
      max-width: 356px;
      height: 40px;
      max-height: unset !important;
      min-height: 40px !important;
      margin: 8px 0 0;
      overflow: hidden;

      @media screen and (max-width: 823px) {
        ${props =>
          props.useSlider
            ? "max-width: calc(100% - 40px); margin: 0 20px;"
            : "max-width: 233px; margin: 0 8px 0 0;"}
      }
      @media screen and (max-width: 576px) {
        ${props =>
          props.numerical ? " max-width: 233px; margin: 0 8px 0 0;" : ""}
      }

      input {
        font-size: ${FontSize.head}px;
        color: ${Color.Black_65};
      }

      &::placeholder {
        font-size: ${FontSize.head}px;
        line-height: 32px;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.25);
      }

      &[disabled] {
        border: solid 1px ${Color.Black_15};
        background-color: ${Color.Black_10};
        color: rgba(0, 0, 0, 0.25);
      }

      &.error-border {
        border-color: ${Color.Red_6};
      }
    }

    textarea.ant-input {
      @media screen and (max-width: 823px) {
        margin: 0 20px;
      }
    }

    .error-text {
      @media screen and (max-width: 823px) {
        margin: 0 20px;
      }
    }
  }

  textarea.ant-input,
  .ant-input-affix-wrapper,
  .numerical-display .ant-input-number {
    &::placeholder {
      font-size: ${FontSize.head}px;
      line-height: 32px;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.25);
    }

    &:hover,
    &:focus {
      border: unset;
      box-shadow: unset;
    }

    &:hover {
      &:not(.has-error) {
        box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
        border: solid 1.5px ${Color.ChtBlue_2};
      }
    }

    &:not(.has-error) {
      &:focus,
      &.ant-input-affix-wrapper-focused,
      &.ant-input-number-focused {
        box-shadow: unset;
        border: solid 1.5px ${Color.ChtBlue_6};
      }
    }

    &.has-error,
    &:hover,
    &:focus {
      border: solid 1.5px ${Color.Red};
    }

    &[disabled] {
      border: solid 1px ${Color.Black_15};
      background-color: ${Color.Black_10};
      color: rgba(0, 0, 0, 0.25);
    }
  }

  textarea.ant-input {
    height: 40px;
    resize: vertical !important;
    padding: 4px 11px;
    font-size: 16px;
    overflow: hidden;
  }

  .question-imgAndMedia,
  .question-img,
  .question-media {
    @media screen and (min-width: 824px) {
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  .numerical-display {
    letter-spacing: 0.48px;

    .question-main {
      margin-bottom: 20px;

      @media screen and (max-width: 823px) {
        margin-bottom: 24px;
      }
    }

    .title-wrapper {
      margin-bottom: 0;
    }

    .tips-label {
      margin: 0 8px 0 0 !important;
      font-weight: normal !important;
    }

    .mandatory-label {
      margin: 0 !important;
      font-weight: normal !important;
    }

    .total-text {
      margin: -11px 0 20px 0;

      .success {
        color: ${Color.ChtBlue_6};
      }

      .error {
        color: ${Color.Red};
      }

      @media screen and (max-width: 823px) {
        padding: 0 20px;
      }
    }

    .error-tag {
      margin: 12px 0 21px 0;
      @media screen and (max-width: 823px) {
        padding: 0 12px 0 17px;
        margin: 1px 0 11px 0;
      }
    }

    .default-wrapper {
      &:not(:last-child) {
        margin-bottom: ${props => (props.useSlider ? "10px" : "26px")};

        @media screen and (max-width: 823px) {
          margin-bottom: ${props => (props.useSlider ? "10px" : "30px")};
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .default-outside {
      padding: 0;
      margin: ${props => (props.useSlider ? "0 0 18px 0" : "0 0 12px 0")};

      @media screen and (max-width: 823px) {
        margin: 0 0 12px 0;
      }
    }
  }

  // Silder全部CSS
  .ant-slider-track {
    position: absolute;
    height: 4px;
    background-color: #91d5ff;
    border-radius: 2px;
    transition: background-color 0.3s;
  }
  .ant-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #91d5ff;
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s,
      transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
  .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
    border-color: #46a6ff;
    box-shadow: 0 0 0 5px #1890ff1f;
  }
  .ant-slider-handle:focus {
    border-color: #46a6ff;
    outline: none;
    box-shadow: 0 0 0 5px #1890ff1f;
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: #1890ff;
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e1;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #69c0ff;
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff;
  }
  .ant-slider-dot-active {
    border-color: #8cc8ff;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

function QuestionEditDisplay(props) {
  const systemTheme = props.survey?._data._themeProps.Theme ?? {};
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const isLink =
    props.survey
      .listConnectedIds()
      .map(id => {
        const found = props.survey.questions.find(q => q.id === id);
        return found ? found.idx : null;
      })
      .findIndex(idx => idx === props.questionIdx) > -1;

  function checkQnAnswer(question) {
    const QnAnswerType = [
      "SINGLE_CHOICE",
      "MULTIPLE_CHOICE",
      "FILL_IN",
      "MATRIX",
    ];
    const questionIndex = question.getIdxDisplay();
    let content = JSON.parse(JSON.stringify(question.content));
    //處理編號是否增加在content
    //{ text: questionIndex + ". ", size: "20", font: "Noto Sans TC" }
    // 樣式
    let numJson =
      props.survey.questionIdxDisplay && questionIndex > 0 && !props.isChild
        ? // ? { text: questionIndex + ". ", size: "20", font: "Noto Sans TC" }
          {
            type: "question-idx",
            children: [
              {
                text: questionIndex + ". ",
                size: "20",
                font: "Noto Sans TC",
              },
            ],
          }
        : "";
    let titleCk = 0;

    function QnReplace(Qn, pointer, offset, s) {
      let QnIndex = parseInt(Qn.substring(2, Qn.indexOf(" answer")));
      if (questionIndex > parseInt(Qn.substring(2, Qn.indexOf(" answer")))) {
        const QnMatch = props.survey.questions.find(
          q => q.getIdxDisplay() === QnIndex
        );
        switch (QnMatch.type) {
          case "SINGLE_CHOICE": {
            if (props.answers[QnMatch._idx].answer != null) {
              return QnMatch.options[props.answers[QnMatch._idx].answer].value;
            }
            return "";
          }
          case "MULTIPLE_CHOICE": {
            if (
              props.answers[QnMatch._idx].answer != null &&
              props.answers[QnMatch._idx].answer.length > 0
            ) {
              let options = [];
              props.answers[QnMatch._idx].answer.sort().map(item => {
                options.push(QnMatch.options[item].value);
              });
              return options.join(",");
            }
            return "";
          }
          case "FILL_IN": {
            if (props.answers[QnMatch._idx].answer != null) {
              return props.answers[QnMatch._idx].answer;
            }
            return "";
          }
          default: {
            return "";
          }
        }
      } else {
        return "";
      }
    }

    function findAndReplace(object, numJson) {
      // let QnRegEx = /@Q\d+ answer/g;
      for (var x in object) {
        if (titleCk == 0) {
          if (typeof object[x] == typeof {} || typeof object[x] == typeof []) {
            if (
              object[x] &&
              object[x][0] &&
              object[x][0].text &&
              object[x][0].text != ""
            ) {
              // if (object[x][0].text.match(QnRegEx)) {
              //   object[x][0].text = object[x][0].text.replace(
              //     QnRegEx,
              //     QnReplace
              //   );
              // }
              if (numJson) object[x].unshift(numJson);
              titleCk += 1;
            } else if (
              object[x].children &&
              object[x].children[0] &&
              object[x].children[0].text &&
              object[x].children[0].text != ""
            ) {
              // if (object[x].children[0].text.match(QnRegEx)) {
              //   object[x].children[0].text = object[x].children[0].text.replace(
              //     QnRegEx,
              //     QnReplace
              //   );
              // }
              if (numJson) object.unshift(numJson);
              titleCk += 1;
            }
            findAndReplace(object[x], numJson);
          }
        } else {
          break;
        }
      }
    }

    findAndReplace(content[0], numJson, titleCk);
    function findQnAnswerAndReplace(content) {
      let QnRegEx = /@Q\d+ answer/g;
      content.map(item => {
        if (item.children) {
          findQnAnswerAndReplace(item.children);
        }
        if (item.text) {
          if (item.text.match(QnRegEx)) {
            item.text = item.text.replace(QnRegEx, QnReplace);
          }
        }
      });
      return;
    }
    findQnAnswerAndReplace(content);

    if (QnAnswerType.indexOf(question.type) === -1) {
      return content;
    }
    return content;
  }

  function getContent(question) {
    let content = checkQnAnswer(question);
    if (content && content.length > 0) {
      return content;
    }
    return [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
  }

  return (
    <Slate
      editor={editor}
      value={getContent(props.question)}
      onChange={value => {
        //don't allow editing in display mode
      }}
    >
      <Wrapper
        {...props}
        id={`questions-${props.questionIdx}`}
        className={
          props.question.type &&
          `${props.question.type.toLowerCase() + "-wrapper"}`
        }
        isChild={props.isChild}
        theme={systemTheme}
      >
        <QuestionDisplayContent
          {...props}
          key={`questions-${props.questionIdx}-content`}
        />
      </Wrapper>
    </Slate>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-left: 12px;
  padding-right: 12px;
  ${props => (props.isChild ? "" : "margin-bottom: 64px;")}
  color: ${Color.ChtBlack_8};

  @media screen and (max-width: 823px) {
    margin-bottom: 16px;
  }
  
  &.text-wrapper {
    margin-bottom: 16px;
    @media screen and (max-width: 823px) {
      padding-left: 22px;
      padding-right: 22px;
    }
  }

  &.matrix-wrapper {
    @media screen and (max-width: 823px) {
      margin-bottom: 40px;
    }
  }

  &.image-wrapper,
  &.video-wrapper {
    margin-bottom: 16px;
    @media screen and (min-width: 824px) {
      margin-right: -12px;
      margin-left: -12px;
    }
  }
  .iframe-wrapper {
    position: relative;
    border-radius: 8px;
    border: solid 0.5px #979797;
    overflow: hidden;
    margin-bottom: 32px;
    height: 0;
    padding-bottom: calc(100% / 16 * 9);
    iframe {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      /* height: 394px; */
    }
    @media screen and (max-width: 823px) {
      border-radius: 4px;
    }
  }

  &.single_choice-wrapper,
  &.multiple_choice-wrapper {
    ${props => (props.isChild ? "" : "margin-bottom: 64px;")}

    @media screen and (max-width: 823px) {
      margin-bottom: 32px;
    }

    .default-wrapper {
      @media screen and (min-width: 824px) {
        margin-left: -5px;
        margin-right: -2px;
      }

      .columns .other-option-input textarea {
        margin-bottom: 6px;
      }
    }
  }

  &.single_choice-wrapper {
    .title-wrapper {
      @media screen and (max-width: 823px) {
        margin-bottom: 8px;
      }
    }
  }

  & p {
    margin-bottom: 0;
  }

  & > .question-number {
    font-size: ${FontSize.title}px;
  }

  & .actions {
    & > .wrapper {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 20px 12px;
      border-radius: 4px;

      & > .action-item {
        cursor: pointer;
      }
    }
    pointer-events: none;
    opacity: 0;
    position: absolute;
    padding-left: 10px;
    right: 0px;
    top: 0px;
    transform: translateX(100%);
  }

  .question-matrix {
    margin-left: -4px;

    .ant-radio-wrapper {
      margin: auto;
      width: 100%;
  
      &:hover {
        .ant-radio-inner {
          border-color: ${props => props.theme.button.primary} !important;
        }
      }
  
      .ant-radio {
        &.ant-radio-checked {
          .ant-radio-inner {
            border-color: ${props => props.theme.button.primary} !important;
        
            &::after {
              background-color: ${props =>
                props.theme.button.primary} !important;
            }
          }
        }
      }
    }
  }

  &.numerical-wrapper {
    @media screen and (max-width: 823px) {
      margin-bottom: 50px;
    }
  }

  &.basic_info-wrapper {
    margin-bottom: 0;
    
    .input-wrapper {
      @media screen and (max-width: 823px) {
        padding: 0 20px;
      }

      .ant-input-affix-wrapper {
        padding: 8px 12px;
        margin-top: 0;

        @media screen and (max-width: 823px) {
          max-width: none;
        }
      }

      .ant-input-suffix {
        display: flex;
        align-items: center;
      }
    }

    .title-wrapper {
      margin-bottom: 8px;

      @media screen and (max-width: 823px) {
        margin-bottom: 16px;
      }

      &.description-true {
        margin-bottom: 0px;

        @media screen and (max-width: 823px) {
          margin-bottom: 8px;
        }
      }
    }

    .main {
      .description-wrapper { 
        margin-bottom: 12px;
      }

      &.validate-true {
        .description-wrapper {
          margin-bottom: 6px;
        }

        .tag {
          display: ${props => (props.question.required ? "inherit" : "none")};
          margin-bottom: 9px;
        }
      }
    }
  }

  &.fill_in-wrapper {
    margin-bottom: unset;

    .title-wrapper {
      margin-bottom: 8px;

      @media screen and (max-width: 823px) {
        margin-bottom: 16px;
      }
    }

    .main {
      .description-wrapper {
        margin-bottom: 14px;
      }

      &.validate-true {
        .description-wrapper {
          margin-bottom: 6px;
        }

        .tag {
          display: ${props => (props.question.required ? "inherit" : "none")};
          margin-bottom: 9px;
        }
      }
    }

    textarea.ant-input {
      margin: 0;
      
      @media screen and (max-width: 823px) {
        margin: 0 20px;
        max-width: calc(100% - 40px);
      }
    }
  }

`;

const NumericalDisplayWrapper = styled.div`
  .option-title-wrapper {
    display: flex;
    margin-bottom: 12px;

    .question-number {
        display: inline-block;
        width: 25px;
        height: 24px;
        color: ${props => props.theme.theme.web.optionSeq.color};
        font-size: ${props => props.theme.theme.web.optionSeq.size}px;
        flex: 0 0 25px;
      }

      .question-text {
        color: ${props => props.theme.theme.web.option.color};
        font-size: ${props => props.theme.theme.web.option.size}px;
        flex: 0 1 auto;
      }
    }
  }

  ${props =>
    props.useSlider &&
    css`
      .option-title-wrapper {
        margin-bottom: 20px;
      }

      .option-item-in-row.cols-1.media-false {
        @media screen and (max-width: 823px) {
          padding: 0px 20px;
        }
      }
    `}
`;

export default QuestionEditDisplay;
