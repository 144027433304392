import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Color, FontSize } from "./Widget";
import RichTextEditor from "./RichTextEditor";
import VideoIframe from "./VideoIframe";

function QuestionPrefix(props) {
  const systemTheme = props.survey?._data._themeProps.Theme ?? {};

  const [validate, setValidate] = useState(false);

  useEffect(() => {
    const regex = /^\s*$/;
    if (props.validate) {
      if (props.answer.answer === null) {
        setValidate(true);
      } else if (
        props.question.type === "MULTIPLE_CHOICE" &&
        props.answer.answer.length === 0
      ) {
        setValidate(true);
      } else if (
        props.question.type === "MATRIX" &&
        props.answer.answer.length < props.question.rows.length
      ) {
        setValidate(true);
      } else if (
        typeof props.answer.answer === "string" &&
        (props.answer.answer.length === 0 || regex.test(props.answer.answer))
      ) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    }
  }, [props.validate, props.answer]);

  return (
    <>
      <Wrapper className="question-main" theme={systemTheme}>
        <QuestionContent {...props} validate={validate} />
        <div className={`main ${validate ? "validate-true" : ""}`}>
          <QuestionSubContent {...props} />
          <div className="tag">
            {props.question.type === "MULTIPLE_CHOICE" && (
              <MultiSelectAnswerLabel
                {...props}
                extraStyle={{ marginRight: props.question.required ? 12 : 0 }}
              />
            )}
            {/* 數字總和題題示 */}
            {props.question?.numericalTotal &&
              props.question.type === "NUMERICAL" && (
                <TipsLabel theme={systemTheme}>
                  選項數值合計需等於 {props.question.numericalTotal}{" "}
                  {props.question.unit}
                </TipsLabel>
              )}
            {validate && props.question.required && <MandatoryLabel />}
          </div>
        </div>
      </Wrapper>
      {(props.question.contentImage || props.question.contentVideo) && (
        <QuestionMedia {...props} />
      )}
    </>
  );
}

const Wrapper = styled.div`
  .tag {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  &.question-main {
    margin: 0 -11px;

    .title-wrapper {
      font-size: ${FontSize.title}px;
      color: ${Color.ChtBlack_8};
      display: flex;
      align-items: baseline;
      padding: 8px 7px 8px 12px;
      margin-bottom: 8px;
      line-height: 1.5em;

      @media screen and (max-width: 823px) {
        padding: 0 23px 0 28px;
      }

      &.description-true,
      &.validate-true {
        margin-bottom: 0;
        @media screen and (max-width: 823px) {
          margin-bottom: 8px;
        }
      }

      .asterisk {
        align-self: flex-start;
        margin-top: -3px;
        margin-right: 3px;
      }

      .question-subject-number {
        font-size: ${FontSize.title}px;
        margin-right: 5px;
      }
    }

    .main {
      padding: 0 11px;
      @media screen and (max-width: 823px) {
        padding: 0 23px 0 28px;
      }
    }

    .description-wrapper {
      font-size: ${props => props.theme.theme.web?.note.size}px;
      color: ${props => props?.theme.theme?.web?.note?.color};
      margin-bottom: 4px;
    }
  }

  .asterisk {
    margin-left: -11px;

    @media screen and (max-width: 640px) {
      margin-left: -11px;
    }
  }

  .main {
    @media screen and (max-width: 640px) {
      font-size: ${props => props?.theme.theme?.mobile?.note?.size}px;
      color: ${props => props?.theme.theme?.mobile?.note?.color};
    }
  }
`;

const QuestionSubject = styled.div`
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0.48px;

  p.paragraph {
    width: 100%;
  }

  span.qidx + p.paragraph {
    flex: 1;
    display: inline;
    min-width: calc(100% - 32px);
  }

  p.paragraph + span.qidx {
  }

  .alignLeft,
  .alignRight,
  .alignCenter,
  .alignJustify {
    text-align: start !important;
  }

  .alignLeft {
    span.qidx {
      position: absolute;
    }
    p.paragraph,
    ul {
      flex: 1;
      display: inline-block;
      min-width: calc(100% - 32px);
      text-align: left;
    }
    ol {
      align-items: flex-start;
    }
    li.listItem {
      text-align: left;
    }
    span.qidx + li.listItem {
      margin-left: 32px;
    }
    span.qidx + p.paragraph {
      display: inline-block;
      text-indent: 32px;
    }
  }

  .alignRight {
    span.qidx {
      position: absolute;
    }
    p.paragraph,
    ul {
      flex: 1;
      display: inline-block;
      min-width: calc(100% - 32px);
      text-align: right;
    }
    ol {
      align-items: flex-start;
    }
    li.listItem {
      text-align: right;
    }
    span.qidx + li.listItem {
      margin-left: 32px;
    }
    span.qidx + p.paragraph {
      display: inline-block;
      text-indent: 32px;
    }
  }

  .alignCenter {
    span.qidx {
      position: absolute;
    }
    p.paragraph,
    ul {
      flex: 1;
      display: inline-block;
      min-width: calc(100% - 32px);
      text-align: center;
    }
    ol {
      align-items: flex-start;
    }
    li.listItem {
      text-align: center;
    }
    span.qidx + li.listItem {
      margin-left: 32px;
    }
    span.qidx + p.paragraph {
      display: inline-block;
      text-indent: 32px;
    }
  }

  .alignJustify {
    span.qidx {
      position: absolute;
    }
    p.paragraph,
    ul {
      flex: 1;
      display: inline-block;
      min-width: calc(100% - 32px);
      text-align: justify;
    }
    ol {
      align-items: flex-start;
    }
    li.listItem {
      text-align: justify;
    }
    span.qidx + li.listItem {
      margin-left: 32px;
    }
    span.qidx + p.paragraph {
      display: inline-block;
      text-indent: 32px;
    }
  }
`;

function QuestionContent(props) {
  const systemTheme = props.survey._data._themeProps.Theme;
  return (
    <div
      className={
        props.question.description && props.validate
          ? "title-wrapper description-true validate-true"
          : props.question.description
          ? "title-wrapper description-true"
          : "title-wrapper"
      }
    >
      {props.question.required && props.survey.asteriskDisplay && (
        <div
          className="asterisk"
          style={{
            color: systemTheme.theme.web.reqire.color,
            fontSize: systemTheme.theme.web.reqire.size,
          }}
        >
          *
        </div>
      )}
      <QuestionSubject theme={systemTheme}>
        <RichTextEditor readOnly={true} theme={systemTheme} />
      </QuestionSubject>
    </div>
  );
}

function QuestionSubContent(props) {
  if (!props.question.description) {
    return null;
  }
  return (
    <div className="description-wrapper">{props.question.description}</div>
  );
}

function QuestionMedia(props) {
  return (
    <QuestionMediaWrapper
      className={
        props.question.contentImage && props.question.contentVideo
          ? "question-imgAndMedia"
          : props.question.contentImage
          ? "question-img"
          : "question-media"
      }
    >
      {props.question.contentImage && (
        <img
          src={props.question.contentImage}
          onError={e => {
            e.target.src = "/images/image-placeholder.png";
            e.target.onError = null;
          }}
          alt="content illustration"
        />
      )}

      {props.question.contentVideo && (
        <div className="iframe-wrapper">
          <VideoIframe
            title={`YouTube Player for content video`}
            src={props.question.contentVideo}
            // width="178"
            // height="100"
            frameBorder="no"
            border="0"
            // frameborder="0"
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          />
        </div>
      )}
    </QuestionMediaWrapper>
  );
}

const QuestionMediaWrapper = styled.div`
  & img {
    width: 100%;
    object-fit: contain;
    border: 0px solid #ccc;
    display: block;
    margin-bottom: 46px;
    @media screen and (max-width: 823px) {
      margin-bottom: 32px;
    }
  }
  .iframe-wrapper {
    position: relative;
    border-radius: 8px;
    border: solid 0px #979797;
    overflow: hidden;
    margin-bottom: 32px;
    height: 0;
    padding-bottom: calc(100% / 16 * 9);
    iframe {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      /* height: 394px; */
    }
    @media screen and (max-width: 823px) {
      border-radius: 4px;
      iframe {
        /* height: 180px; */
      }
    }
  }

  &.question-imgAndMedia {
    img {
      margin-bottom: 32px;
    }
  }
`;

const ChooseCountText = styled.div`
  color: ${Color.ChtBlue_5};
  font-size: 14px;
  margin: 6px 0px 22px 0px;
  span {
    &.blue {
      color: ${Color.ChtBlue_5};
    }
    &.red {
      color: ${"#fa5050"};
    }
  }
`;

function MandatoryLabel(props) {
  return (
    <div className="mandatory-label-wrapper">
      <div
        className="mandatory-label"
        style={{
          border: `1px solid #fa5050`,
          borderRadius: 11,
          padding: "0px 7px",
          backgroundColor: Color.Red_1,
          display: "inline-block",
          // 2022-05-27 : 企業版型
          fontSize: `${
            props?.theme?.web?.reqire?.size
              ? props?.theme?.web?.reqire?.size
              : FontSize.caption
          }px`,
          fontWeight: 500,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.67em",
          letterSpacing: "normal",
          color: Color.Red_6,
        }}
      >
        必填
      </div>
    </div>
  );
}

function TipsLabel(props) {
  return (
    <div className="tips-label-wrapper">
      <div
        className="tips-label"
        style={{
          border: `1px solid ${props.theme.theme?.web?.hint?.color}`,
          borderRadius: 11,
          padding: "0px 7px",
          backgroundColor: "rgba(109, 190, 255, 0.2)",
          display: "inline-block",
          fontSize: `${props.theme.theme?.web?.hint?.size}px`,
          color: `${props.theme.theme?.web?.hint?.color}`,
          marginBottom: 8,
          marginRight: 8,
          fontWeight: 500,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.67em",
          letterSpacing: "normal",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

function MultiSelectAnswerLabel(props) {
  const systemTheme = props.survey._data._themeProps.Theme;

  const _getMinimumChoiceCount = question => {
    try {
      return `${props.question.advanced.limit.from}`;
    } catch (err) {
      return "";
    }
  };

  const _getMaximumChoiceCount = question => {
    try {
      return `${props.question.advanced.limit.to}`;
    } catch (err) {
      return "";
    }
  };

  const minChoiceCount = _getMinimumChoiceCount(props.question);
  const maxChoiceCount = _getMaximumChoiceCount(props.question);

  if (!minChoiceCount || !maxChoiceCount) {
    return null;
  }

  const chooseCount = props?.answer?.answer?.length || 0;
  // 還需要選幾個
  const needCount =
    minChoiceCount > chooseCount ? minChoiceCount - chooseCount : 0;

  // 還需要減少選幾個
  const needLessCount =
    chooseCount > maxChoiceCount ? Math.abs(maxChoiceCount - chooseCount) : 0;

  return (
    <div style={{ ...props.extraStyle }}>
      <div
        style={{
          border: `1px solid ${systemTheme.theme?.web?.hint?.color}`,
          borderRadius: 11,
          padding: "0px 7px",
          backgroundColor: "rgba(109, 190, 255, 0.2)",
          display: "inline-block",
          fontSize: `${systemTheme.theme?.web?.hint?.size}px`,
          color: `${systemTheme.theme?.web?.hint?.color}`,
          marginBottom: 8,
        }}
      >
        {minChoiceCount !== maxChoiceCount
          ? `請選擇 ${minChoiceCount} ~ ${maxChoiceCount} 選項`
          : `須選擇 ${minChoiceCount} 個選項`}
      </div>
      {chooseCount > 0 && (
        <ChooseCountText>
          <span>{`已選擇 ${chooseCount} 個`}</span>
          {needCount > 0 && (
            <>
              <span>，</span>
              <span class="red">{`還需選 ${needCount} 個`}</span>
            </>
          )}
          {needLessCount > 0 && (
            <>
              <span>，</span>
              <span class="red">{`請減少 ${needLessCount} 個`}</span>
            </>
          )}
        </ChooseCountText>
      )}
    </div>
  );
}

function ErrorText(props) {
  return (
    <div
      style={{
        height: 36,
        marginBottom: 16,
        fontSize: `${FontSize.caption}px`,
        paddingTop: 3,
        color: Color.Red,
      }}
    >
      {props.children}
    </div>
  );
}

export {
  QuestionPrefix,
  QuestionContent,
  QuestionMedia,
  MandatoryLabel,
  ErrorText,
};
