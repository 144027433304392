import _ from "lodash";
class LogicMatcher {
  constructor() {
    this.logicList = [];
    this.questions = [];
    this.matchMap = {};
    this.ruleMatchList = {};
    this.uansRuleLogic = {};
    // 修正邏輯判斷
    this.logicMatch = {};
    // 交叉選項回覆數量限制
    this.limitData = null;
    // 交叉選項回覆數量限制 - 符合條件的
    this.mathLimit = [];
    this.answers = {};
  }

  setSurveyData({ logicList, survey, answers }) {
    this.logicList = logicList;
    this.questions = survey.questions;
    this.answers = answers;
    logicList.map(item => {
      this.ruleMatchList["l" + item.id] = new Array(item.rules.length).fill(0);
      // 修正邏輯判斷
      this.logicMatch["l" + item.id] = false;
      for (const rule of item.rules) {
        if (rule.condition === "uans") {
          this.uansRuleLogic["l" + item.id] = {
            UansQuestionIndex: this.questions.findIndex(
              _ => _.id === rule.question
            ),
            question: rule.question,
            logic: item,
          };
        }
      }
    });
  }

  setLimitData(limitData) {
    this.limitData = limitData;
  }

  validateAnswers({ begin, end }, survey, answers, noPass) {
    for (const q of this.questions.slice(begin, end)) {
      if (
        !q.validate(answers[q.idx]) &&
        !this.shouldJumpOver(q, answers[q.idx]) && //跳題規則不擋
        !this.shouldChildren(
          q,
          survey.getParentQuestionByQuestionId(q.id),
          answers
        ) &&
        !this.shouldForceQuit()
      ) {
        const questionId = "questions-" + q.idx;
        const element = document.getElementById(questionId);
        if (element) {
          window.scrollTo({
            top: element.offsetTop,
            behavior: "smooth",
          });
        }
        return false;
      }
    }
    if (!this.shouldForceQuit() && noPass) {
      return false;
    }
    if (noPass) {
      return false;
    }
    return true;
  }

  optionsIsClosedList() {
    return this.limitData?.Options.filter(opt => opt.IsClosed) ?? [];
  }

  cancelLogic({ question, answer }) {
    let matched = [];
    let matchQuestions = [question.id];
    for (const logic of this.logicList) {
      for (const rule of logic.rules) {
        if (rule.question === question.id) {
          matchQuestions.push(rule.question);
          this.ruleMatchList["l" + logic.id][logic.rules.indexOf(rule)] = 0;
          this.matchLogic(question, answer, logic, false);
        }
      }
    }
    matchQuestions.map(item => {
      this.matchMap[item] = {
        question,
        answer,
        matched,
      };
    });
  }

  match({ question, answer, answers }) {
    this.matchMap = {};
    let currentQuestionIndex = this.questions.findIndex(
      _ => _.id === question.id
    );

    let matched = [];
    let matchQuestions = [question.id];
    // 處理邏輯規則
    for (const logic of this.logicList) {
      if (this.matchLogic(question, answer, logic, true)) {
        matched.push(logic);
        if (
          logic.type === "prevent" &&
          Array.isArray(answer.answer) &&
          logic.rules[0].question === question.id
        ) {
          answer.answer = answer.answer.filter(
            item => logic.action.indexOf(item) === -1
          );
        }
      }
      for (const rule of logic.rules) {
        if (rule.question === question.id) {
          matchQuestions.push(rule.question);
        }
      }
    }

    matchQuestions.forEach(item => {
      this.matchMap[item] = {
        question,
        answer,
        matched,
      };
    });

    let uansMatched = [];
    let matchUansQuestions = [];
    for (const key in this.uansRuleLogic) {
      if (this.uansRuleLogic[key].UansQuestionIndex < currentQuestionIndex) {
        if (
          this.matchLogic(
            this.questions[this.uansRuleLogic[key].UansQuestionIndex],
            answers[this.uansRuleLogic[key].UansQuestionIndex],
            this.uansRuleLogic[key].logic,
            true
          )
        ) {
          uansMatched.push(this.uansRuleLogic[key].logic);
        }
      }
      for (const rule of this.uansRuleLogic[key].logic.rules) {
        matchUansQuestions.push(rule.question);
      }
    }
    matchUansQuestions.forEach(item => {
      this.matchMap[item] = {
        question,
        answer,
        matched: uansMatched,
      };
    });

    // 交叉選項回覆數量，預設只有真實的問卷才會有此行為
    // 預覽不呈現
    if (this.limitData) {
      this.compare(question, answer, answers);
    }

    // 修正邏輯判斷
    return matched;
  }

  matchLogic(question, answer, logic, ruleTrigger) {
    for (const rule of logic.rules) {
      if (rule.question === question.id && ruleTrigger) {
        if (this.matchRule(question, answer, rule)) {
          this.ruleMatchList["l" + logic.id][logic.rules.indexOf(rule)] = 1;
        } else {
          this.ruleMatchList["l" + logic.id][logic.rules.indexOf(rule)] = 0;
        }
      }
    }
    if (logic.constraint === "any") {
      if (this.ruleMatchList["l" + logic.id].indexOf(1) > -1) {
        // 修正邏輯判斷
        this.logicMatch["l" + logic.id] = true;
        return true;
      } else {
        // 修正邏輯判斷
        this.logicMatch["l" + logic.id] = false;
        return false;
      }
    } else if (logic.constraint === "all") {
      if (this.ruleMatchList["l" + logic.id].indexOf(0) > -1) {
        // 修正邏輯判斷
        this.logicMatch["l" + logic.id] = false;
        return false;
      } else {
        // 修正邏輯判斷
        this.logicMatch["l" + logic.id] = true;
        return true;
      }
    }
    return false;
  }

  /**
   * 判斷 answer 是否有滿足 邏輯規則的條件選項
   * eq : 等於
   * ueq : 不等於
   * ans : 有回答
   * uans : 無回答
   * @param {object} question
   * @param {object} answer
   * @param {object} rule
   * @returns
   */
  matchRule(question, answer, rule) {
    switch (rule.condition) {
      case "eq": {
        return this.matchRuleEq(question, answer, rule);
      }
      case "ueq": {
        return this.matchRuleUeq(question, answer, rule);
      }
      case "ans": {
        return this.matchRuleAns(question, answer, rule);
      }
      case "uans": {
        return this.matchRuleUans(question, answer, rule);
      }
      default: {
        return false;
      }
    }
  }

  matchRuleEq(question, answer, rule) {
    if (answer.answer === null) {
      return false;
    }
    if (question.type === "SINGLE_CHOICE") {
      for (const opt of rule.options) {
        if (opt === answer.answer) {
          return true;
        }
      }
    } else if (question.type === "MULTIPLE_CHOICE") {
      for (const opt of rule.options) {
        if (answer.answer != null) {
          if (answer.answer.indexOf(opt) > -1) {
            return true;
          }
        }
      }
    } else if (question.type === "MATRIX") {
      for (const opt of answer.answer) {
        if (
          opt[0] === question.rows.indexOf(rule.MatrixField) &&
          rule.options.indexOf(opt[1]) > -1
        ) {
          return true;
        }
      }
    }
    return false;
  }

  matchRuleUeq(question, answer, rule) {
    if (answer.answer === null) {
      return false;
    }
    if (question.type === "SINGLE_CHOICE") {
      if (rule.options.indexOf(answer.answer) === -1) {
        return true;
      }
    } else if (question.type === "MULTIPLE_CHOICE") {
      for (const opt of rule.options) {
        if (answer.answer.indexOf(opt) > -1) {
          return false;
        }
      }
      return true;
    } else if (question.type === "MATRIX") {
      for (const opt of answer.answer) {
        if (opt[0] === question.rows.indexOf(rule.MatrixField)) {
          if (rule.options.indexOf(opt[1]) === -1) {
            return true;
          }
        }
      }
    }
    return false;
  }

  matchRuleAns(question, answer, rule) {
    if (answer.answer === null) {
      return false;
    } else if (Array.isArray(answer.answer) && answer.answer.length === 0) {
      return false;
    } else if (
      typeof answer.answer === "string" &&
      answer.answer.length === 0
    ) {
      return false;
    }
    return true;
  }

  matchRuleUans(question, answer, rule) {
    if (answer.answer === null || answer.answer.length === 0) {
      return true;
    }
    return false;
  }

  shouldChildren(question, parentQuestion, answers) {
    if (parentQuestion) {
      let parentAnswer = answers[parentQuestion.idx].answer;
      if (parentAnswer !== null) {
        switch (parentQuestion.type) {
          case "SINGLE_CHOICE":
            if (
              parentQuestion.options[parentAnswer].connectTo &&
              parentQuestion.options[parentAnswer].connectTo === question.id
            ) {
              return null;
            }
            answers[question.idx].answer = null;
            return true;
          case "MULTIPLE_CHOICE":
            let chlidrenQuestions = [];
            parentAnswer.map(item => {
              if (parentQuestion.options[item].connectTo) {
                chlidrenQuestions.push(parentQuestion.options[item].connectTo);
              }
            });
            if (
              chlidrenQuestions.length > 0 &&
              chlidrenQuestions.indexOf(question.id) !== -1
            ) {
              return null;
            }
            answers[question.idx].answer = null;
            return true;
          default:
            answers[question.idx].answer = null;
            return true;
        }
      }
      answers[question.idx].answer = null;
      return true;
    }
    return null;
  }

  shouldJumpOver(question, answer) {
    for (const logic of this.logicList) {
      if (this.logicMatch["l" + logic.id] && logic.type === "jump") {
        let ruleIdxList = [];
        logic.rules.map(item => {
          let temp = this.questions.findIndex(_ => _.id === item.question);
          if (
            this.ruleMatchList["l" + logic.id][logic.rules.indexOf(item)] === 1
          ) {
            ruleIdxList.push(temp);
          }
        });
        let beginIdx = -1;
        if (logic.constraint === "any") {
          beginIdx = Math.min(...ruleIdxList);
        } else {
          beginIdx = Math.max(...ruleIdxList);
        }

        const endIdx = this.questions.findIndex(_ => _.id === logic.action);
        const currIdx = this.questions.findIndex(_ => _.id === question.id);

        if (beginIdx < currIdx && currIdx < endIdx) {
          answer.answer = null;
          this.cancelLogic({ question, answer });
          return true;
        }
      } else if (
        this.logicMatch["l" + logic.id] &&
        logic.type === "forceQuit"
      ) {
        let beginIdx = -1;
        logic.rules.map(item => {
          let temp = this.questions.findIndex(_ => _.id === item.question);
          if (beginIdx < temp) {
            beginIdx = temp;
          }
        });
        const currIdx = this.questions.findIndex(_ => _.id === question.id);

        if (beginIdx < currIdx) {
          answer.answer = null;
          this.cancelLogic({ question, answer });
          return true;
        }
      }
    }

    // 交叉數量上限檢核
    if (
      this.mathLimit &&
      Array.isArray(this.mathLimit) &&
      this.mathLimit.length > 0
    ) {
      // 檢查是否有滿足條件
      const filter = this.mathLimit.filter(f => f.ActionType === "0");

      const sort = this.limitData.QuestionDataList.sort(
        (a, b) => b.QuestionSeq - a.QuestionSeq
      );
      const last = sort[0];
      if (filter && last.QuestionSeq < question.idx) return true;
    }

    return null;
  }

  isDoubleQuestion() {
    return this.limitData?.QuestionList?.length === 2;
  }

  shouldJumpOptionFull(question, answer, jumpOptFullIndex) {
    if (!["SINGLE_CHOICE"].includes(question.type)) return;
    const optionsIsClosedList = this.optionsIsClosedList();

    if (this.isDoubleQuestion()) {
      const ansIdList = this.answers?.map(ans => {
        if (ans.answer === null) return null;
        return ans.optionsInfo[ans.answer]?.id;
      });
      this.answers?.forEach(ans => {
        if (ans.answer === null) return;
        const ansId = ans.optionsInfo[ans.answer]?.id;
        jumpOptFullIndex.current = null;
        optionsIsClosedList.forEach(list => {
          if (list.ActionType !== "0") return;
          let allOptIncluded =
            JSON.stringify(list.OptionList.sort()) ===
            JSON.stringify(ansIdList.filter(Boolean).sort());
          if (!allOptIncluded) return;
          if (list.OptionList[1] !== ansId) return;
          jumpOptFullIndex.current = question._idx;
        });
      });
    }

    if (!this.isDoubleQuestion()) {
      this.answers?.forEach(ans => {
        if (ans.answer === null) return;
        const ansId = ans.optionsInfo[ans.answer]?.id;
        jumpOptFullIndex.current = null;
        optionsIsClosedList.forEach(list => {
          if (list.ActionType !== "0") return;
          if (!list.OptionList.includes(ansId)) return;
          jumpOptFullIndex.current = question._idx;
        });
      });
    }
  }

  isOptionDisable(question, optIdx) {
    let disable = false;
    this.answers?.forEach(ans => {
      if (ans.answer !== null) {
        const ansId = ans.optionsInfo[ans.answer]?.id;
        const optionsIsClosedList = this.limitData?.Options.filter(
          opt => opt.IsClosed && opt.ActionType === "1"
        );
        optionsIsClosedList.forEach(limitOpt => {
          if (limitOpt.OptionList.includes(ansId)) {
            const secondOptId = limitOpt.OptionList.filter(
              id => id !== ansId
            )[0];
            const idx = question.arrOptions.findIndex(
              opt => opt.id === secondOptId
            );
            disable = optIdx === idx;
          }
        });
      }
    });
    return disable;
  }

  shouldDisableOpt(question, optIdx) {
    if (question.type !== "SINGLE_CHOICE") return;
    const optionsIsClosedList = this.optionsIsClosedList();
    let shouldDisable = false;
    if (!this.answers) return;
    this.answers.forEach(ans => {
      if (ans.answer === null || Array.isArray(ans.answer)) return;
      const ansId = ans.optionsInfo[ans.answer]?.id;
      optionsIsClosedList.forEach(list => {
        if (list.ActionType !== "1") return;
        if (!list.OptionList.includes(ansId)) return;
        const anotherAnsId = list.OptionList.filter(id => id !== ansId)[0];
        const optionIdx = question.arrOptions.findIndex(
          opt => opt.id === anotherAnsId
        );
        if (optionIdx !== optIdx) return;
        shouldDisable = true;
      });
    });
    return shouldDisable;
  }

  shouldDisableOption(question, optIdx) {
    // move outside
    // 跨題選項防呆，將有中的邏輯規則 Condition 循一輪
    // 如果 A題 - 選項1 有選到
    // 鎖定 B題 - 選項2
    const keys = Object.keys(this.matchMap);
    for (const key of keys) {
      const match = this.matchMap[key];
      console.log("match", optIdx, match);
      if (match) {
        for (const logic of match.matched) {
          if (
            logic.type === "preventByQuestion" &&
            (question.type === "SINGLE_CHOICE" ||
              question.type === "MULTIPLE_CHOICE")
          ) {
            // 選項帶進來的參數為index，需轉換成id
            const optId = question.options[optIdx].id;
            if (
              logic.action === question.id &&
              logic.blockOptionList.indexOf(optId) > -1
            ) {
              for (const answer of this.answers) {
                for (const option of answer.optionsInfo) {
                  if (logic.blockOptionList.indexOf(option.id) > -1) {
                    if (question.type === "SINGLE_CHOICE") {
                      if (answer.answer !== null) {
                        var optionIdx = null;
                        answer.optionsInfo.forEach((item, idx) => {
                          if (item.id === option.id) {
                            optionIdx = idx;
                          }
                        });
                        if (optionIdx !== null && answer.answer === optionIdx) {
                          answer.answer = null;
                        }
                      }
                    }
                    if (question.type === "MULTIPLE_CHOICE") {
                      const index = answer.answer?.indexOf(
                        answer.optionsInfo.indexOf(option)
                      );
                      if (index > -1) {
                        answer.answer.splice(index, 1);
                      }
                    }
                  }
                }
              }
              return true;
            }
          }
        }
      }
    }
    const result = this.matchMap[question.id];
    if (result) {
      for (const logic of result.matched) {
        if (
          logic.type === "prevent" &&
          logic.rules[0].question === question.id &&
          question.type === "MULTIPLE_CHOICE"
        ) {
          // if (logic.action === optIdx) {
          if (logic.action.indexOf(optIdx) > -1) {
            return true;
          }
        }
      }
    }
    if (this.isDoubleQuestion()) {
      return this.shouldDisableOpt(question, optIdx);
    }
    if (!this.isDoubleQuestion()) {
      if (this.limitData?.QuestionList?.indexOf(question.id) > -1) {
        const opt = question.options[optIdx];
        const optId = opt.id;
        const { Options: options } = this.limitData;

        const findIndex = options.findIndex(data => {
          if (data.IsClosed && data.ActionType === "1") {
            const { OptionList: optionList } = data;
            const subFindIndex = optionList.findIndex(find => find === optId);
            return subFindIndex > -1;
          }
          return false;
        });
        if (findIndex > -1) {
          return true;
        }
      }
      return false;
    }
  }

  shouldDisableOptionWithLimitText(question, optIdx) {
    if (this.isDoubleQuestion()) {
      return this.shouldDisableOpt(question, optIdx);
    }

    if (!this.isDoubleQuestion()) {
      if (this.limitData?.QuestionList?.indexOf(question?.id) > -1) {
        const optId = question.arrOptions[optIdx]?.id;
        const { Options: options } = this.limitData;
        const findIndex = options.findIndex(data => {
          if (data.IsClosed && data.ActionType === "1") {
            const { OptionList: optionList } = data;
            const subFindIndex = optionList.findIndex(find => find === optId);
            return subFindIndex > -1;
          }
          return false;
        });
        if (findIndex > -1) {
          return true;
        }
      }
      return false;
    }
  }

  shouldForceQuit() {
    for (const result of Object.keys(this.matchMap).map(
      k => this.matchMap[k]
    )) {
      if (!result) {
        continue;
      }

      for (const logic of result.matched) {
        if (this.logicMatch["l" + logic.id] && logic.type === "forceQuit") {
          return true;
        }
      }
    }

    // 交叉數量上限檢核
    if (
      this.mathLimit &&
      Array.isArray(this.mathLimit) &&
      this.mathLimit.length > 0
    ) {
      const filter = this.mathLimit.filter(f => f.ActionType === "0");
      if (filter) return true;
    }

    return false;
  }

  /**
   * @param {*} currQuestion 本次選擇選項之問題
   * @param {*} currAnswer 本次選擇之選項
   * @param {*} answers 先前收集的答案
   */
  compare(currQuestion, currAnswer, answers) {
    const { answer, optionsInfo, questionId } = currAnswer;
    const { QuestionList: questionList, Options: options } = this.limitData;
    let transToOptionId = []; // 將 Answer 中存放的 option idx 轉成 option id
    let expandOptionId = []; // 剛 option id 展開
    this.mathLimit = []; // 新清空資料

    // 判斷抽共用，判斷是否需要檢核交叉數量上限
    if (!this.checkIsLimitSetting(currQuestion, currAnswer)) return;

    // 取出有設定的題目的選項，並轉換成Option Id
    transToOptionId.push(this.transToOptionId(questionId, currAnswer.answer));

    // 差集目前選擇問題，取得另一題設定的題型
    const otherQusetionId = _.difference(questionList, [questionId]);
    if (otherQusetionId) {
      otherQusetionId.map(item => {
        const _answer = answers.find(find => find.questionId === item);
        transToOptionId.push(this.transToOptionId(item, _answer.answer));
      });
    }

    // 如果有整理過的為 undefined / null, 表示沒滿足條件，不處理
    if (transToOptionId.findIndex(f => !f) > -1) return;

    if (transToOptionId.length == 1) {
      expandOptionId = transToOptionId[0];
    } else if (transToOptionId.length > 1) {
      for (let i = 1; i < transToOptionId.length; i++) {
        const curr = transToOptionId[i];

        for (let j = 0; j < transToOptionId.length - 1; j++) {
          const prev = transToOptionId[j];

          for (let x = 0; x < curr.length; x++) {
            for (let y = 0; y < prev.length; y++) {
              expandOptionId.push([curr[x], prev[y]]);
            }
          }
        }
      }
    }
    // 已經關閉的選項組合
    const closedOptionSet = options.filter(f => f.IsClosed);

    // 從已經關閉的選項組合中，挑出有包含此次答案的組合
    const calc = closedOptionSet.filter(set => {
      const find = expandOptionId.find(
        expend => _.difference(set.OptionList, expend).length === 0
      );
      if (find) return true;
      else return false;
    });
    this.mathLimit = calc;
  }

  /**
   * 判斷是否有設定交叉數量上限之題目，如果有回傳有，則需重拉目前問卷收集狀態
   * @param {*} currQuestion
   * @param {*} currAnswer
   * @returns true or false
   */
  reacquireStatistics(currQuestion, currAnswer) {
    if (this.checkIsLimitSetting(currQuestion, currAnswer)) {
      const { answer, optionsInfo, questionId } = currAnswer;
      const { QuestionList: questionList, Options: options } = this.limitData;
      let transToOptionId = [];

      // 取出有設定的題目的選項，並轉換成Option Id
      transToOptionId.push(this.transToOptionId(questionId, currAnswer.answer));

      if (
        options.findIndex(itm =>
          itm.OptionList.findIndex(subItm => subItm === transToOptionId[0])
        ) > -1
      )
        return true;
    } else {
      return false;
    }
  }

  /**
   * 確認是否為交叉數量上限可運算的題型
   * 需為單選、多選
   * 需有設定交叉數量上限
   * 需包含在設定之中
   * @param {*} currQuestion
   * @param {*} ccurrAnswer
   */
  checkIsLimitSetting(currQuestion, currAnswer) {
    // 需有設定才會進行後續運算
    if (!this.limitData) return false;

    if (this.limitData) {
      const { answer, optionsInfo, questionId } = currAnswer;
      const { QuestionList: questionList, Options: options } = this.limitData;

      // 設定目前只能設定單選、多選，所以如果不是單選、多選則不檢核
      if (
        currQuestion.type !== "SINGLE_CHOICE" &&
        currQuestion.type !== "MULTIPLE_CHOICE"
      )
        return false;

      // 此選項的問題，並不包含於設定之中，則不進行後續運算
      if (questionList.findIndex(item => item === questionId) < 0) return false;
    }

    return true;
  }

  /**
   * 交叉數量上限檢核
   * @param {} questionId 點選的問題Id
   * @param {*} answer 對應之答案
   */
  transToOptionId(questionId, answer) {
    const question = this.questions.find(q => q.id === questionId);

    switch (question.type) {
      case "SINGLE_CHOICE": {
        if (answer !== null) {
          return [question.options[answer].id];
        }
        break;
      }
      case "MULTIPLE_CHOICE": {
        if (Array.isArray(answer) && answer.length > 0) {
          let arr = [];
          answer.map(answerItem => {
            arr.push(question.options[answerItem].id);
          });
          return arr;
        }
        break;
      }
      default:
        break;
    }
  }
}

export default LogicMatcher;
