/* validation input comp in questionnaire */
// libs
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Survey from "../Contexts/SurveyContext";
import * as SvgIcon from "./SvgIcon";
import * as Ant from "antd";
import Widget, { Color, FontSize } from "./Widget";
import Vcode from "react-vcode";

function ValidationCodeInput(props) {
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");
  const [vcode, setVcode] = useState(null);
  const key = "msg";
  const [isBlock, setIsBlock] = useState(false);

  const handleChangeCode = () => {
    if (props.isError) props.setShowNoPassMsg(false);
    setValue("");
    if (!isBlock) {
      vcode.onClick();
    } else {
      Ant.message.warning({
        content: "3秒內限換1次！",
        key,
        duration: 1,
      });
    }
  };

  useEffect(() => {
    setIsBlock(false);
  }, []);

  useEffect(() => {
    setIsBlock(true);
    setTimeout(() => setIsBlock(false), 3000);
  }, [code]);

  if (value.length >= 4 && code) {
    if (value.toLowerCase() === code.toLowerCase()) {
      props.setNoPass(false);
    } else {
      props.setNoPass(true);
    }
  } else if (value.length > 1 && value.length < 4) {
    props.setNoPass(true);
  }

  return (
    <Wrapper className={props?.isError ? "error" : ""}>
      <div style={{ marginBottom: 8 }}>驗證碼</div>
      <Widget.FlexRow style={{ alignItems: "flex-end" }}>
        <Ant.Input
          style={{ fontSize: 16 }}
          placeholder="輸入右邊圖片內容"
          value={value}
          onChange={e => {
            if (props.isError) {
              props.setShowNoPassMsg(false);
            }
            setValue(e.target.value);
          }}
        />
        <div className="vcodeWrapper">
          <Vcode
            width={130}
            height={40}
            onChange={v => setCode(v)}
            onClick={v => setValue("")}
            ref={obj => setVcode(obj)}
            options={{ fontSizeMin: 18, fontSizeMax: 22 }}
          />
        </div>
        <div
          style={{
            width: 60,
            fontSize: `${FontSize.caption}px`,
            color: "#0082c9",
            cursor: "pointer",
            textAlign: "center",
            marginLeft: 8,
          }}
          onClick={handleChangeCode}
        >
          換一張
        </div>
      </Widget.FlexRow>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 12px;
  @media screen and (max-width: 823px) {
    padding: 0 23px 0 28px;
  }
  input {
    width: 180px;
    height: 40px;
    margin-right: 16px;
    @media screen and (max-width: 823px) {
      width: 142px;
      margin-left: 4px;
    }
    &:hover {
      border: solid 1px ${Color.MPurple_6_85};
      color: ${Color.Black_65};
      &::placeholder {
        color: ${Color.Black_65};
      }
    }
    &:focus {
      /* -webkit-filter: blur(1px); */
      /* filter: blur(1px); */
      -webkit-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      -moz-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      border: solid 1px ${Color.LightPurple_45};
      color: ${Color.Black_65};
      &::placeholder {
        color: ${Color.Black_65};
      }
    }
    &:filled {
      border: solid 1px ${Color.MPurple_6_85};
      color: ${Color.Black_65};
      &::placeholder {
        color: ${Color.Black_65};
      }
    }
    &:disable {
      border: solid 1px ${Color.Black_15};
      background-color: ${Color.Black_10};
      color: ${Color.Black_65};
      &::placeholder {
        color: ${Color.Black_25};
      }
    }
  }
  input::placeholder {
    font-family: "Noto Sans TC", "Helvetica Neue", "Consolas";
    font-size: ${FontSize.head}px;
    line-height: 1.5em;
    height: 1.5em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${Color.Black_25};
  }
  &.error {
    input {
      border: solid 1px ${Color.Red_6} !important;
      color: ${Color.Black_65} !important;
      &::placeholder {
        color: ${Color.Black_65} !important;
      }
    }
  }
  .vcodeWrapper {
    position: relative;
    width: 130px;
    height: 40px;
    &::after {
      content: "";
      width: 87px;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export default ValidationCodeInput;
