import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import hexToRgba from "hex-to-rgba";
import _ from "lodash";

import Widget, { Color, FontSize } from "./Widget";
import NumericalOption from "./NumericalOption";
import QuestionDisplay from "./LiveQuestion";
import * as SvgIcon from "./SvgIcon";
import VideoIframe from "./VideoIframe";

/**
 * 填寫介面：選項呈現
 * @param {} param0
 * @returns
 */
function OptionRows({ cols = 2, ...props }) {
  function mapOption(option, useMediaLayout, _idx) {
    return (
      <div
        className={`option-item-in-row cols-${cols} media-${useMediaLayout}`}
        key={(option && option.id) || `null-${_idx}` || `${new Date()}`}
      >
        {option && (
          <QuestionOption
            idx={option.idx}
            displayIdx={_idx}
            option={option}
            useMediaLayout={useMediaLayout}
            cols={cols}
            {...props}
          />
        )}
      </div>
    );
  }

  const useMediaLayout = !!props.question.options.find(
    opt => opt.mediaType && opt.url
  );

  let options = props.options;

  if (cols === 1) {
    return options.map((option, _idx) => (
      <>
        <OptionRow
          key={option.idx}
          cols={cols}
          className={
            useMediaLayout
              ? `media-wrapper columns-col${cols}`
              : `default-wrapper columns-col${cols}`
          }
          isChild={props.isChild}
        >
          {mapOption(option, useMediaLayout, _idx)}
        </OptionRow>
        {props.isChild ? null : CreateChildQuestions([option], cols, props)}
      </>
    ));
  }

  let currRow = [];
  let rowElems = [];
  let rowIdx = 0;

  for (let i = 0; i < options.length; i++) {
    currRow.push(options[i]);

    if ((i + 1) % cols === 0) {
      rowElems.push(
        <OptionRow
          key={rowIdx++}
          cols={cols}
          className={
            useMediaLayout
              ? `media-wrapper cols-outside columns-col${cols}`
              : `default-wrapper cols-outside columns-col${cols}`
          }
          isChild={props.isChild}
        >
          {[...currRow].map((opt, _idx) => {
            const rowElementsFilter = rowElems.filter(
              r => r.props.isChild === props.isChild
            );
            const actualDisplayIdx = rowElementsFilter.length * cols + _idx;
            return mapOption(opt, useMediaLayout, actualDisplayIdx);
          })}
        </OptionRow>
      );

      // 依據這一列的option，產生子題
      let childs = [];
      if (!props.isChild) {
        childs = CreateChildQuestions(currRow, cols, props);

        if (childs.length > 0) {
          childs.forEach(child => {
            rowElems.push(child);
          });
        }
      }
      currRow = [];
    }
  }

  // 選項最後一行，因為上方條件餘數為0時，才會進if
  // 例如 options.length = 7， col = 4
  // 到這邊會剩下3筆 option 沒產，所以多一次判斷補產
  if (currRow.length > 0) {
    while (currRow.length < cols) {
      currRow.push(null); // put dummy
    }

    rowElems.push(
      <OptionRow
        key={rowIdx++}
        cols={cols}
        className={
          useMediaLayout
            ? `media-wrapper cols-outside columns-col${cols}`
            : `default-wrapper cols-outside columns-col${cols}`
        }
      >
        {[...currRow].map((opt, _idx) => {
          const rowElementsFilter = rowElems.filter(
            r => r.props.isChild === false
          );
          const actualDisplayIdx = rowElems.length * cols + _idx;
          return mapOption(opt, useMediaLayout, actualDisplayIdx);
        })}
      </OptionRow>
    );

    // 依據這一列的option，產生子題
    let childs = [];
    if (!props.isChild) {
      childs = CreateChildQuestions(currRow, cols, props);

      if (childs.length > 0) {
        childs.forEach(child => {
          rowElems.push(child);
        });
      }
    }
  }

  return rowElems;
}

function QuestionOption({ idx, option, displayIdx, ...props }) {
  let otherChoice = false;
  const systemTheme = props.survey?._data._themeProps.Theme;

  const answerData = props?.answers?.[props.questionIdx] || {};
  const othersValue = answerData.othersValue || "";

  if (props.answers[props.questionIdx].answer === idx) {
    otherChoice = true;
  } else if (
    Array.isArray(props.answers[props.questionIdx].answer) &&
    props.answers[props.questionIdx].answer.indexOf(idx) !== -1
  ) {
    otherChoice = true;
  }

  const othersOptionError =
    props.question.type === "MULTIPLE_CHOICE"
      ? props.validate &&
        option.type === "others" &&
        !props.question.validate(props.answer) &&
        otherChoice &&
        !props.question.otherFilledValidate(props.answer)
      : props.validate &&
        option.type === "others" &&
        !props.question.validate(props.answer) &&
        otherChoice;

  const extraStyle = othersOptionError
    ? {
        // borderColor: Color.Red,
      }
    : {};

  let CheckIconComp = Ant.Radio;
  if (props.question.type === "MULTIPLE_CHOICE") {
    CheckIconComp = Ant.Checkbox;
  }

  let innerElement = null;
  let otherElement = null;
  let otherText = null;

  const optionDisabled = props.shouldDisableOption(props.question, idx);

  if (option.mediaType === "image" && option.url) {
    innerElement = (
      <div className="image-option-wrapper">
        <img
          src={option.url}
          onError={e => {
            e.target.src = "/images/image-placeholder.png";
            e.target.onError = null;
          }}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
          alt="option illustration"
        />
      </div>
    );
  } else if (option.mediaType === "video" && option.url) {
    innerElement = (
      <div
        className={
          props.cols > 2 ? "cols video-option-wrapper" : "video-option-wrapper"
        }
      >
        <VideoIframe
          width="100%"
          height="100%"
          title={`YouTube Player ${idx}`}
          frameBorder="no"
          border="0"
          src={option.url}
          // frameborder="0"
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      </div>
    );
  } else if (option.type === "others") {
    function onInputChange(e) {
      if (props.question.type === "MULTIPLE_CHOICE") {
        let answerArr = Array.isArray(props.answer.answer)
          ? [...props.answer.answer]
          : [];
        // 當輸入其他選項的文字時，自動幫使用者勾選
        if (!answerArr.includes(idx)) {
          answerArr = [...answerArr, idx];
        }
        props.updateAnswer(props.questionIdx, {
          answer: answerArr,
          othersValue: e.target.value,
        });
      } else {
        // 當輸入其他選項的文字時，自動幫使用者勾選
        props.updateAnswer(props.questionIdx, {
          answer: idx,
          othersValue: e.target.value,
        });
      }
    }

    otherText = (
      <span
        className={props.cols > 2 ? "cols" : ""}
        style={{
          whiteSpace: "nowrap",
          marginBottom: 8,
        }}
      >
        <span
          className="other-option-text"
          style={{ whiteSpace: "break-spaces" }}
        >
          {/* CHT:{option.data && `，請說明原因`} */}
          {/** TODO for 阿茂，需確認此段介面 */}
          {option.data?.useOtherText ? option.data?.otherText : ""}
        </span>
      </span>
    );

    otherElement = option.data?.otherIsShowText && (
      <OtherOptionInputWrapper
        className="other-option-input"
        isRequired={option.data?.isMandatory}
        theme={systemTheme}
      >
        <Ant.Input.TextArea
          value={othersValue}
          placeholder="請輸入"
          autoSize
          style={{ ...extraStyle }}
          size="small"
          disabled={optionDisabled}
          onChange={onInputChange}
          className={othersOptionError ? "has-error" : ""}
        />
        {othersOptionError && option.data && (
          <div
            style={{
              color: Color.Red,
              fontSize: `${FontSize.caption}px`,
              marginTop: 3,
            }}
          >
            {option.data.validateType === "none"
              ? `${option.data.errorMsg}。`
              : option.data.validateType === "number"
              ? `${option.data.errorMsg}。在${option.data.from}和${option.data.to}數字之間`
              : `${option.data.errorMsg}。在${option.data.from}和${option.data.to}個字數之間`}
          </div>
        )}
      </OtherOptionInputWrapper>
    );
  }

  const inputProps = !props.answer
    ? { checked: false }
    : props.question.type === "SINGLE_CHOICE"
    ? {
        checked: props.answer.answer === idx,
        onChange: e => {
          if (e.target.checked) {
            props.updateAnswer(props.questionIdx, {
              answer: idx,
            });
          }
        },
      }
    : {
        checked:
          Array.isArray(props.answer.answer) &&
          props.answer.answer.indexOf(idx) > -1,
        onChange: e => {
          const answerArr = Array.isArray(props.answer.answer)
            ? props.answer.answer
            : [];
          if (e.target.checked) {
            const _idx = answerArr.indexOf(idx);
            if (_idx === -1) {
              answerArr.push(idx);
              props.updateAnswer(props.questionIdx, {
                answer: answerArr,
              });
            }
          } else {
            const _idx = answerArr.indexOf(idx);
            if (_idx > -1) {
              answerArr.splice(_idx, 1);
              props.updateAnswer(props.questionIdx, {
                answer: answerArr,
              });
            }
          }
        },
      };

  if (props.useMediaLayout) {
    return (
      <OptionWrapper
        style={{
          backgroundColor: inputProps.checked ? "#e2f2ff" : "transparent",
          border: inputProps.checked ? "1px solid" : "0.5px solid",
          borderColor: inputProps.checked ? "#209cff" : "#efefef",
          borderRadius: 8,
          overflow: "hidden",
        }}
        cols={props.cols}
        theme={systemTheme}
        numerical={props.type === "NUMERICAL"}
      >
        <Widget.FlexRow
          style={{ alignItems: "flex-start", padding: 8 }}
          className={
            props.cols >= 2
              ? `columns default-outside default-outside-media ${option.type ===
                  "others" && "question-other"}`
              : `default-outside default-outside-media ${option.type ===
                  "others" && "question-other"}`
          }
        >
          <CheckIconComp
            {...inputProps}
            disabled={optionDisabled}
            checked={optionDisabled ? false : inputProps.checked}
            style={{
              width: `${option.type !== "others" ? "100%" : "unset"}`,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "unset",
            }}
          >
            <Widget.FlexRow style={{ alignItems: "flex-start" }}>
              {props.survey.optionIdxDisplay && (
                <span className="question-number">{`${displayIdx + 1}.`}</span>
              )}
              <span
                className="question-text"
                style={{ marginBottom: otherElement ? 8 : 0 }}
              >
                <span style={{ display: "inline-block" }}>
                  {option?.value ?? ""}
                </span>
                {otherText ?? ""}
              </span>
            </Widget.FlexRow>
          </CheckIconComp>
          {otherElement || ""}
        </Widget.FlexRow>
        {innerElement && <div style={{ width: "100%" }}>{innerElement}</div>}
      </OptionWrapper>
    );
  }

  if (props.question.type === "NUMERICAL") {
    return (
      <OptionWrapper
        className="numerical-option-wrapper"
        cols={props.cols}
        theme={systemTheme}
        numerical
      >
        <div className="option-title-wrapper">
          {props.survey.optionIdxDisplay && (
            <span className="question-number">{`${displayIdx + 1}.`}</span>
          )}
          <span className="question-text">{option?.value ?? ""}</span>
        </div>
        <NumericalOption idx={option.idx} option={option} {...props} />
      </OptionWrapper>
    );
  }

  return (
    <OptionWrapper
      className={
        optionDisabled ? "disabled" : inputProps.checked ? "active" : ""
      }
      cols={props.cols}
      theme={systemTheme}
      numerical={props.question.type === "NUMERICAL"}
    >
      <Widget.FlexRow
        style={{ alignItems: "flex-start", padding: 8 }}
        className={
          props.cols > 2 ? "columns default-outside" : "default-outside"
        }
      >
        <CheckIconComp
          {...inputProps}
          disabled={optionDisabled}
          checked={optionDisabled ? false : inputProps.checked}
          style={{
            width: `${option.type !== "others" ? "100%" : "unset"}`,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            margin: "unset",
          }}
        >
          <Widget.FlexRow style={{ alignItems: "flex-start" }}>
            {props.survey.optionIdxDisplay && (
              <span className="question-number">{`${displayIdx + 1}.`}</span>
            )}
            <span
              className="question-text"
              style={{ marginBottom: otherElement ? 8 : 0 }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginBottom: ["SINGLE_CHOICE", "MULTIPLE_CHOICE"].includes(
                    props.question.type
                  )
                    ? 0
                    : 8,
                  wordBreak: "normal",
                }}
              >
                {option.value ?? ""}
              </span>
              {otherText ?? ""}
              {props.shouldDisableOptionWithLimitText(props.question, idx) && (
                <div
                  style={{
                    display: "inline-block",
                    color: Color.Red,
                    fontSize: 14,
                    paddingLeft: "8px",
                  }}
                >
                  已額滿，請選擇其他選項
                </div>
              )}
            </span>
          </Widget.FlexRow>
        </CheckIconComp>
        {otherElement || ""}
      </Widget.FlexRow>
      {/* 選項：其他 */}
      {innerElement && <div style={{ flex: 1 }}>{innerElement}</div>}
    </OptionWrapper>
  );
}
/**
 * 填寫介面：產生子題問題與選項
 * @param {*} currRow
 * @param {*} cols
 * @param {*} param2
 * @returns
 */
function CreateChildQuestions(
  currRow = [],
  cols,
  { questionIdx, answers, ...props }
) {
  const { answer, optionsInfo } = answers[questionIdx];
  const { type } = props.question;
  const systemTheme = props.survey?._data._themeProps.Theme ?? {};

  // 列出子題型之問題ID
  // 條件為 選項需設定子題，且選項已點選
  // 選項需設定子題 > option?.connectTo
  // 選項已點選 > option.id === optionsInfo[].id
  // 未答題前 answer === null，需增加判斷
  const optionsWhichSetChild = currRow.filter(option => {
    // 如果選項額滿的話要隱藏子題
    if (props.shouldDisableOptionWithLimitText(props.question, option?.idx)) {
      return false;
    }

    if (answer !== null && option?.connectTo) {
      // 單選題答案只有1個
      if (type === "SINGLE_CHOICE") {
        if (option.id === optionsInfo[answer].id) {
          return true;
        }
      }

      // 多選題答案為陣列
      if (type === "MULTIPLE_CHOICE") {
        if (
          answer.find(find => option.id === optionsInfo[find].id) !== undefined
        ) {
          return true;
        }
      }
    }
    return false;
  });

  let renderChildQuestions = [];
  optionsWhichSetChild.forEach((option, idx) => {
    // 尋找子題題目內容
    const mainQuestion = props.survey.questions[questionIdx];
    // 找出所有子題的ID
    let allChildQuestions = [];
    mainQuestion.arrOptions.map(opt => {
      if (opt.connectTo) {
        allChildQuestions.push(opt.connectTo);
      }
    });

    const childQuestion = props.survey.questions.find(
      question => question.id === option.connectTo
    );

    /**
     * MA-0226-001 問卷子母題無法送出
     * Review 2024-03-10 應該不是從allChildQuestion中找，而是從answers中找
     * 以下這段是直接抓最後一筆的idx，這樣會有問題
     * const finalIdx =
     * option.idx + 1 > allChildQuestions.length
     *   ? questionIdx + allChildQuestions.length
     *   : questionIdx + (option.idx + 1);
     *
     * 應該是要從answers中找questionId符合的idx
     */
    const finalIdx = _.findIndex(answers, { questionId: childQuestion.id });
    // 用子題的ID從answers.questionId找到answer
    const answer = _.find(answers, { questionId: childQuestion.id });

    if (childQuestion) {
      renderChildQuestions.push(
        <ChildQuestionRow key={`${childQuestion.id}-${idx}`} cols={cols}>
          <TitleBlock theme={systemTheme}>
            <SvgIcon.NextOption color={systemTheme.button.primary} size={24} />
            <a className="title">接續「{option.value}」選項</a>
          </TitleBlock>
          <div className="contentBlock">
            <QuestionDisplay
              {...props}
              key={`${option.connectTo}-${idx}`}
              options={childQuestion.arrOptions}
              questionIdx={finalIdx}
              question={childQuestion}
              answers={answers}
              answer={answer}
              isChild={childQuestion.isChild}
              otherOption={option}
            />
          </div>
        </ChildQuestionRow>
      );
    }
  });

  return renderChildQuestions;
}

const OptionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  .ant-radio-wrapper {
    margin: auto;

    &:hover {
      .ant-radio-inner {
        border-color: ${props => props.theme.button.primary} !important;
      }
    }

    .ant-radio {
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: ${props => props.theme.button.primary} !important;
      
          &::after {
            background-color: ${props => props.theme.button.primary} !important;
          }
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    height: 100%;
    &:hover {
      .ant-checkbox {
        &::after {
          border-color: ${props => props.theme.button.primary} !important;
        }
      }

      .ant-checkbox-inner {
        border-color: ${props => props.theme.button.primary} !important;
      }
    }

    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: ${props => props.theme.button.primary} !important;
          border-color: ${props => props.theme.button.primary} !important;
        }
      }

      .ant-checkbox-input {
        &:focus + .ant-checkbox-inner {
          border-color: ${props => props.theme.button.primary};
        }
      }
    }
  }

  &.active {
    background-color: ${props =>
      hexToRgba(props.theme?.background?.find(b => b.id === 2).color, "0.2")};
    animation-name: activeColor;
    animation-duration: 2s;
  }

  &:active {
    background-color: ${props =>
      hexToRgba(props.theme.button.secondary, "0.4")};
  }

  &.numerical-option-wrapper:active {
    background-color: transparent;
  }

  &.disabled {
    background-color: #f5f5f5;
  }

  @keyframes activeColor {
    from {
      background-color: ${props =>
        hexToRgba(props.theme.button.secondary, "0.2")};
    }
    to {
      background-color: ${props =>
        hexToRgba(props.theme.button.secondary, "0.2")};
    }
  }
  
  .image-option-wrapper {
    overflow: hidden;
    width: 100%;
  }

  .video-option-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: black;
    width: 100%;

    @media screen and (max-width: 823px) {
      width: calc( 100% - 14px );
      margin-left: 7px;
      margin-bottom: 7px;
      border-radius: 4px;
      border: solid 0.5px #979797;
    }

    &.cols {
      @media screen and (max-width: 823px) {
        width: calc( 100% - 10px );
        margin-left: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        border: solid 0.5px #979797;
      }
    }
  }

  .default-outside {
    font-size: ${FontSize.head}px;
    align-items: flex-start;
    flex: 1;
    padding: 0 8px;
    flex-direction: column !important;

    @media screen and (max-width: 823px) {
      ${props =>
        props.numerical ? "padding: 0 8px 0 0px; margin: 12px 0;" : ""}
      align-items: flex-start !important;
      flex-direction: column !important;

      span.ant-radio + * {
        padding: 0 3px 0 7px;
      }

      &.question-other {
        span.ant-radio + * {
          padding: 0 7px;
        }
      }
    }

    .question-number {
      font-size: ${props => props?.theme?.theme?.web?.optionSeq?.size}px;
      color: ${props => props?.theme?.theme?.web?.optionSeq?.color};
      font-weight: normal;
      display: inline-block;
      width: 25px;
      min-height: 24px;
      line-height: 24px;
      letter-spacing: normal;

      @media screen and (max-width: 823px) {
        width: 25px;
      }
    }

    .question-text {
      font-size: ${props => props?.theme?.theme?.web?.option?.size}px;
      color: ${props => props?.theme?.theme?.web?.option?.color};
      flex: 1;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 0.36px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }

    .ant-checkbox {
      top: 0;
      margin-top: 4px;
    }

    .ant-radio {
      margin-top: 4px;
    }

    &.columns {
      align-items: flex-start !important;
      flex-direction: column !important;
      textarea {}
    }

    @media screen and (max-width: 823px) {
      label {
        width: 100%;
        margin-bottom: 4px;
      }
      textarea {
        max-width: unset !important;
      }
    }

    &-media {
      align-items: flex-start !important;
      flex-direction: column !important;

      label {
        width: 100%;
      }

      textarea {
        width: 100%;
        max-width: unset;
        margin-bottom: 8px;
      }

      &.columns {
        align-items: flex-start !important;
        flex-direction: column !important;

        @media screen and (max-width: 823px) {
          textarea {
            max-width: 340px;
          }
        }
      }
    }
  }

  .other-option {
    display: flex;
    align-items: center;
    &-text {
    }
    &-input {
      flex: 1;
      width: 100%;

      textarea {
        width: 100%;
        max-width: unset;
        height: 40px;
        min-height: 40px !important;
        line-height: 32px;
        font-size: ${FontSize.head}px;
        padding: 2.5px 0 2.5px 12px;
        border-radius: 4px;
        border: solid 1.5px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        color: ${Color.Black_65};
        overflow: hidden;

        @media screen and (max-width: 823px) {
          margin-bottom: 6px;
        }

        /* &::placeholder {
          font-size: ${FontSize.head}px;
          line-height: 32px;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.25);
        } */

        /* &:hover {
          -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
          -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
          box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
          border: solid 1px ${Color.ChtBlue_2};
        }

        &:focus {
          box-shadow: unset;
          border: solid 1px #0083ec;

          @media screen and (max-width: 823px) {
            -webkit-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
            -moz-box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
            box-shadow: 0 0 4px 2px ${Color.ChtBlue_2};
            border: solid 1px ${Color.ChtBlue_2};
          }
        }

        &[disabled] {
          border: solid 1px ${Color.Black_15};
          background-color: ${Color.Black_10};
          color: rgba(0, 0, 0, 0.25);
        } */
      }
    }

    &.cols {
      flex-direction: column;
      align-items: flex-start;

      & .other-option-input {
        margin-left: 0;
      }
    }
  }

  .numerical-block {
    @media screen and (max-width: 823px) {
      width: 100%;
    }

    .tag {
      border-radius: 11px; 
      margin: 13px 0px 16px 0px;
      @media screen and (max-width: 823px) {
        margin: 0px 0px 16px 0px;
      }
    }
    
    .answer-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      @media screen and (max-width: 823px) {
        width: 100%;
        align-items: initial;
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
      }

      .slider-block {
        width: 437px;
        margin: 0 63px 0 33px;

        @media screen and (max-width: 823px) {
          width: 100%;
          margin: 0 0 10px 0;
        }
      }

      .input-block {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 823px) {
          margin-left: 0px;
          align-items: center;
        }
        
        &.non-slider {
          margin-left: 0px;

          .ant-form-item-control-input {
            margin-bottom: 2px;
          }

          .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
          }
          
          .ant-input-number {
            width: 200px;
            margin: 0 8px 0 0;

            @media screen and (max-width: 823px) {
              margin: 0 16px 0 0;
            }
          }

          .ant-input-number-input {
            height: 40px;
            padding: 8px 12px;
            text-align: right;
          }
        }

        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
        }

        .ant-input-number {
          width: 80px;
          margin: 0 8px 0 0;

          @media screen and (max-width: 823px) {
            width: 100px;
          }
        }

        .ant-input-number-input {
          height: 40px;
          padding: 8px 12px 10px;
          text-align: right;
        }
    
        .input-col {
          width: 100px;

          @media screen and (max-width: 823px) {
            width: 160px;
          }

          .input {
            height: 40px; 
            text-align: right;
          }

          .error {
            height: 20px;
            font-size: 12px;
            color: #ff4d4f; 
            display: block;

            @media screen and (max-width: 823px) {
              display: none;
            }
          }
        }
      
        .unit{
          height: 100%;

          @media screen and (max-width: 823px) {
            line-height: 2.8;
          }
        }
      }
    }
  }
`;

const OptionRow = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 8px;

  &.media-wrapper {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: unset;

    &.columns-col1:not(:nth-last-of-type(-n)) {
      margin-bottom: 16px;
    }

    &.columns-col2:not(:nth-last-of-type(-n + 1)) {
      margin-bottom: 16px;
    }

    &.columns-col3:not(:nth-last-of-type(-n + 1)) {
      margin-bottom: 16px;
    }

    &.columns-col4:not(:nth-last-of-type(-n + 1)) {
      margin-bottom: 16px;
    }

    .cols-1.media-true {
      .other-option-input {
        width: 100%;

        textarea {
          max-width: unset;
        }
      }
    }

    .cols-2.media-true,
    .cols-3.media-true,
    .cols-4.media-true {
      .other-option-input {
        width: 100%;

        textarea {
          max-width: unset;
        }
      }
    }

    @media screen and (min-width: 824px) {
      margin-right: -12px;
      margin-left: -12px;
    }
  }

  &.cols-outside {
    @media screen and (max-width: 823px) {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 823px) {
    margin-bottom: 4px;
    ${props => (props.isChild ? "" : "width: calc(100vw - 40px);")}
  }

  & > .option-item-in-row {
    display: flex;
    flex: 1;
    margin-right: 10px;

    .video-option-wrapper {
      height: 423px;
    }

    &.cols-1.media-false {
      @media screen and (max-width: 823px) {
        padding: 0px 12px;
      }
    }

    &.cols-2 {
      flex: 0 1 50%;
      margin-right: 16px;

      .video-option-wrapper {
        height: 207px;
      }

      @media screen and (max-width: 823px) {
        margin-right: 0;

        .video-option-wrapper {
          height: 82px;
        }
      }
    }

    &.cols-3 {
      flex: 0 1 33.33%;
      margin-right: 10px;

      .video-option-wrapper {
        height: 137px;
      }
    }

    &.cols-4 {
      flex: 0 1 25%;
      margin-right: 9px;

      .video-option-wrapper {
        height: 102px;
      }
    }

    @media screen and (max-width: 823px) {
      flex: unset;
      width: 100%;

      &.cols-2,
      &.cols-3,
      &.cols-4 {
        flex: 0 0 calc(50% - 5px);
        margin-right: unset;
        textarea {
          margin-bottom: 6px;
        }
      }

      .video-option-wrapper {
        height: 173px;
      }
    }

    @media screen and (max-width: 823px) {
      &.media-true {
        margin-right: 10px;
      }
    }
  }

  & > .option-item-in-row:last-child {
    margin-right: 0px;
  }
`;

const ChildQuestionRow = styled.div`
  background-color: ${Color.BBlack_1};
  margin-left: -5px;
  margin-right: ${props => (props.cols > 1 ? "-19px" : "-3px")};

  @media screen and (max-width: 823px) {
    margin-left: ${props => (props.cols > 1 ? "0px" : "20px")};
    margin-right: ${props => (props.cols > 1 ? "10px" : "0px")};
  }

  margin-bottom: 16px;
  padding-top: 6px;

  .contentBlock {
    padding-left: 20px;

    @media screen and (max-width: 823px) {
      padding-left: 0px;
    }
  }
`;

const TitleBlock = styled.div`
  padding: 0 12px 0 5px;
  font-size: 16px;
  letter-spacing: 0.21px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .title {
    margin-left: 3px;
    color: ${props => props.theme.button.primary};
  }
`;

const OtherOptionInputWrapper = styled.div`
  padding-left: ${props => (props.isRequired ? "37px" : "25px")};
  position: relative;

  &::before {
    display: ${props => (props.isRequired ? "block" : "none")};
    content: "*";
    color: ${Color.Red};
    font-size: ${FontSize.body}px;
    position: absolute;
    top: 0;
    left: 25px;
  }

  .ant-input {
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
  }
`;

export { OptionRows, OptionWrapper };
