import React, { useState } from "react";
import styled from "styled-components";
import { Color, FontSize } from "../Components/Widget";
import * as Ant from "antd";
import { navigate } from "gatsby";
import qs from "query-string";

import chtLogo from "../images/CHTLogo_color.svg";

/**
 * 填答完成頁面
 * @param {title} string 頁面抬頭
 * @param {topImg} link 頁面圖檔
 * @param {btnText} string 按鈕名稱 
 * @param {btnLink} link 按鈕連結 
 * @param {preview} bool 是否預覽
 * @param {status} string 問卷狀態主要 check partially-full 是否額滿
 * @param {isTestData} bool 測試資料
 * @return {FinishPage}
 */

function FinishPage({
  title,
  topImg,
  btnText,
  btnLink,
  preview,
  status,
  isTestData,
  ...props
}) {
  const [btnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("isTestData", isTestData);

  const onSubmit = () => {
    if (!preview) {
      if (btnLink) {
        window.location.replace(btnLink);
      } else {
        onClose();
      }
    }
  };

  const onClose = () => {
    setLoading(true);
    navigate("https://www.cht.com.tw");
  };

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  return (
    <Wrapper>
      <Header />
      <Main>
        <div className="banner">
          {topImg ? (
            <img src={topImg} alt="中華電信問卷" />
          ) : (
            <picture>
              <source
                media="(min-width: 824px)"
                srcset="../images/EndPage_Web.png"
              />
              <img src="../images/EndPage_Mobile.png" alt="中華電信問卷" />
            </picture>
          )}
        </div>
        {props.children ? (
          <div className="title">{props.children}</div>
        ) : (
          <div className="title">{title}</div>
        )}
        <ButtonGroup status={status}>
          {status === "partially-full" && (
            <Ant.Button
              type="primary"
              className="btn btn-blue"
              style={{ marginBottom: 24 }}
              disabled={btnDisabled}
              onClick={() => {
                navigate(
                  `/q?id=${qs.parse(window.location.search).id}&ProvideType=1${
                    isTestData ? "&test=true" : ""
                  }`
                );
              }}
            >
              返回問卷
            </Ant.Button>
          )}
          <Ant.Button
            type="primary"
            className="btn btn-blue"
            style={{
              border: status === "partially-full" ? "none" : "#53b3ff",
              color: status === "partially-full" ? "#209cff" : "#ffffff",
              backgroundColor:
                status === "partially-full"
                  ? "rgba(109, 190, 255, 0.2)"
                  : "#209cff",
            }}
            disabled={btnDisabled}
            onClick={() => onSubmit()}
          >
            {btnText}
          </Ant.Button>
        </ButtonGroup>
      </Main>
      <Footer>
        <img src={chtLogo} alt="中華電信" />
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #f4faff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.div`
  display: none;
`;

const Main = styled.div`
  display: block;
  width: 100%;
  max-width: 800px;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: 40px;
  @media screen and (max-width: 823px) {
    min-height: 532px;
    margin-top: 0;
    border-radius: 0;
  }
  .banner {
    width: 800px;
    /* min-height: 227px; */
    overflow: hidden;
    display: block;
    /* border: solid 1px #cccccc; */
    /* background-color: #cccccc; */
    @media screen and (max-width: 823px) {
      width: 100%;
      height: 0px;
      padding-bottom: calc(100% * (227 / 800));
    }
    @media screen and (max-width: 639px) {
      padding-bottom: calc(100% * (151 / 375));
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .title {
    text-align: center;
    font-size: ${FontSize.largeTitle}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: 0.48px;
    text-align: center;
    color: ${Color.ChtBlue_8};
    padding: 42px 36px 0px;
    @media screen and (max-width: 823px) {
      padding: 20px 20px 0px;
      letter-spacing: 0.32px;
      line-height: 1.33rem;
    }
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 36px 60px;
  flex-direction: ${props =>
    props.status === "partially-full" ? "column" : "inherit"};

  @media screen and (max-width: 823px) {
    padding-top: 96px;
    padding-bottom: 204px;
  }

  .btn {
    min-width: 240px;
    height: 48px;
    box-shadow: 1px 1px 0.5px 0 rgba(0, 20, 37, 0.1);
    font-size: ${FontSize.head}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: 2px;
    text-align: center;
    position: relative;

    @media screen and (max-width: 823px) {
      min-width: 98px;
      height: 40px;
    }
  }

  .btn-blue {
    border: ${props =>
      props.status === "partially-full" ? "none" : "inherit"};
    border-color: ${props =>
      props.status === "partially-full" ? "" : Color.ChtBlue_5};
    background-color: ${Color.ChtBlue_5};
    color: #ffffff;

    &:hover {
      border-color: ${Color.ChtBlue_4};
      background-color: ${Color.ChtBlue_4};
    }

    &:active {
      border-color: ${Color.ChtBlue_8};
      background-color: ${Color.ChtBlue_8};
    }

    &:focus {
      border-color: #0691ff;
      background-color: #0691ff;

      &::after {
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        display: block;
        border: solid 1px #f4f6fc;
        background-color: transparent;
      }
    }

    &[disabled] {
      background-color: ${Color.Black_15};
      border-color: transparent;
      color: ${Color.Black_45};
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 110px;
    height: auto;
  }
  @media screen and (max-width: 832px) {
    height: 75px;
    img {
      width: 80px;
    }
  }
`;

export default FinishPage;
