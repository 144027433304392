import React, { useState, useEffect } from "react";
import * as Ant from "antd";
import styled, { css } from "styled-components";

import Widget, { Font } from "./Widget";

import _ from "lodash";

const NumericalOption = ({ idx, option, ...props }) => {
  const { optionMinLimit, optionMaxLimit } = option;
  const { unit, numericalStyle, decimalPlace, required } = props.question;
  const systemTheme = props.survey?._data._themeProps.Theme ?? {};

  // validate 是 Questionnaire.js 帶進子代的，點選下一步、送出時會經過 logicMatcher.validateAnswers 檢核，不通過則給定false
  const { validate: showValidationUi } = props;
  const answers = props.answer?.answer ? props.answer.answer : [];
  const answerIdx = answers.findIndex(e => e.id === option.id);
  const useSlider = numericalStyle == 0;

  // 滑桿式下方標註屬性
  let marks = new Object();
  marks[optionMinLimit] = optionMinLimit;
  marks[optionMaxLimit] = optionMaxLimit;

  const [isError, setIsError] = useState(false);
  const [helpText, setHelpText] = useState("");
  const [currEditType, setCurrEditType] = useState();

  const check = () => {
    if (answerIdx == -1 || !answers[answerIdx]?.value) {
      // 點選下一步 or 送出 且此題為必填，則需檢核並顯示錯誤
      if (showValidationUi && required && !isNum(answers[answerIdx]?.value)) {
        setIsError(true);
        setHelpText("輸入數值");
      } else {
        setIsError(false);
        if (useSlider) {
          setHelpText(``);
        } else {
          setHelpText(`須介於 ${optionMinLimit} ~ ${optionMaxLimit} 之間`);
        }
      }
    } else {
      if (answers[answerIdx].value > optionMaxLimit) {
        setIsError(true);
        if (useSlider) {
          setHelpText(
            `數值超過${_.round(answers[answerIdx].value - optionMaxLimit, 4)}`
          );
        } else {
          setHelpText(
            `須介於 ${optionMinLimit} ~ ${optionMaxLimit} 之間，超過${answers[
              answerIdx
            ].value - optionMaxLimit}`
          );
        }
      } else {
        setIsError(false);
        if (useSlider) {
          setHelpText(``);
        } else {
          setHelpText(`須介於 ${optionMinLimit} ~ ${optionMaxLimit} 之間`);
        }
      }
    }
  };

  const edit = (editValue, editType) => {
    // input 跟 Slider 連動，會發生一種狀況
    // 當 輸入值 > Slider的 max 時， Slider 會自動修正成 max，並觸發 onChange
    // 因此多這個判斷
    // 2022-09-08 ALVIN 多判斷0
    console.log("editvalue = ", editValue);
    if (isNum(editValue) && editType === currEditType) {
      const answerClone = Array.isArray(answers) ? [...answers] : [];
      if (answerIdx !== -1) {
        // 已回答
        // 2022-09-08 ALVIN 多判斷0
        if (!isNum(editValue) && (!editValue || editValue === "")) {
          // 已回答，但修改成空白，清除資料
          answerClone.splice(answerIdx, 1);
        } else {
          // 修改選項，
          answerClone[answerIdx].value = parseFloat(editValue);
        }
      } else if (isNum(editValue) || (editValue && editValue !== "")) {
        // 2022-09-08 ALVIN 多判斷0
        // 尚未回答，新增
        answerClone.push({ id: option.id, value: parseFloat(editValue) });
      }
      console.log("answerClone: ", answerClone);
      props.updateAnswer(props.questionIdx, {
        answer: answerClone,
      });
    }
  };

  const isNum = val => {
    // 2022-09-08 ALVIN 0判斷為數字
    if (val === 0) return true;
    return !isNaN(val);
  };

  // 小數點後幾位四捨五入算式
  const round = (num, editType) => {
    const _decimalPlace = decimalPlace ? decimalPlace : 0;
    let m = Number(
      (Math.abs(num) * Math.pow(10, _decimalPlace)).toPrecision(15)
    );

    var calc = (Math.round(m) / Math.pow(10, _decimalPlace)) * Math.sign(num);

    // 2022-09-19 ALVIN 增加NaN判斷
    if (isNaN(calc)) {
      calc = 0;
    }

    edit(calc, editType);
  };

  useEffect(() => {
    check();
  });

  return (
    <NumericalBlock>
      <AnswerBlock useSlider={useSlider}>
        {useSlider && (
          <SliderWrapper theme={systemTheme}>
            <Ant.Slider
              min={optionMinLimit}
              max={optionMaxLimit}
              marks={marks}
              onFocus={() => setCurrEditType("slider")}
              onChange={e => edit(e, "slider")}
              value={
                answerIdx != -1 ? answers[answerIdx].value : optionMinLimit
              }
            />
          </SliderWrapper>
        )}
        <Ant.Form.Item
          validateStatus={isError ? "error" : ""}
          help={helpText ? helpText : ""}
        >
          <InputNumberWrapper useSlider={useSlider}>
            <Ant.InputNumber
              className={`${isError ? "has-error" : ""}`}
              onFocus={() => setCurrEditType("inputNumber")}
              onChange={e => edit(e, "inputNumber")}
              onBlur={() =>
                round(
                  answerIdx != -1 ? answers[answerIdx].value : null,
                  "inputNumber"
                )
              }
              value={answerIdx != -1 ? answers[answerIdx].value : null}
              controls={false}
              // min={optionMinLimit}
              // max={optionMaxLimit}
            />
            <Font.Body className="unit">{unit}</Font.Body>
          </InputNumberWrapper>
          {/* <div class="help-text">{`須介於 ${optionMinLimit} ~ ${optionMaxLimit} 之間`}</div> */}
        </Ant.Form.Item>
      </AnswerBlock>
    </NumericalBlock>
  );
};

const NumericalBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .help-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
`;

const AnswerBlock = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 823px) {
    flex-direction: column;
  }

  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
  }

  .ant-form-item-with-help {
    margin-bottom: 0px;
  }

  .ant-form-item-explain {
    font-size: 12px;
  }

  ${props =>
    props.useSlider &&
    css`
      .ant-form-item {
        margin-bottom: 0;
        width: auto;
      }

      .ant-form-item-with-help {
        margin-bottom: 20px;

        @media screen and (max-width: 823px) {
          margin-bottom: 24px;
        }
      }
    `};
`;

const InputNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;

  .ant-input-number {
    width: 200px;
    height: 40px;
    margin-right: 8px;

    @media screen and (max-width: 823px) {
      width: 100px;
      flex: 1;
      margin-right: 16px;
    }
  }

  .ant-input-number-input {
    width: 200px;
    height: 40px;
    text-align: right;
  }

  .unit {
    width: 127px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;

    @media screen and (max-width: 823px) {
      flex: 0 1 97px;
    }
  }

  ${props =>
    props.useSlider &&
    css`
      .ant-input-number {
        width: 80px;
        height: 40px;
        margin-right: 8px;

        &:hover {
          box-shadow: 0 0 4px 2px #6dbeff;
          border: solid 1.5px #6dbeff;
        }

        @media screen and (max-width: 823px) {
          width: 100px;
          flex: 0 0 auto;
        }
      }

      .ant-input-number-input {
        width: 80px;
        height: 40px;
        text-align: right;

        @media screen and (max-width: 823px) {
          width: 100px;
        }
      }

      .unit {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 0 1 127px;

        @media screen and (max-width: 823px) {
          flex: 1;
        }
      }
    `};
`;

const SliderWrapper = styled.div`
  flex: 1;
  margin: 0px 63px 0px 40px;

  @media screen and (max-width: 823px) {
    margin: 0 0 15px 0;
  }

  .ant-slider {
    &:hover {
      .ant-slider-track {
        background-color: ${props => props.theme.button.primary} !important;
      }

      .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: ${props => props.theme.button.primary} !important;
      }
    }

    .ant-slider-track {
      background-color: ${props => props.theme.button.primary};
    }

    .ant-slider-dot-active {
      border-color: ${props => props.theme.button.primary};
    }

    .ant-slider-handle {
      border-color: ${props => props.theme.button.primary};

      &:focus {
        border-color: ${props => props.theme.button.primary};
      }

      &.ant-tooltip-open {
        border-color: ${props => props.theme.button.primary};
      }
    }
  }
`;

export default NumericalOption;
