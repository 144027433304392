const themeColor = {
  ["#209CFF"]: {
    primaryBtnActive: "#0083EC",
  },
  ["#1155CC"]: {
    primaryBtnActive: "#1155CC",
  },
  ["#414CB2"]: {
    primaryBtnActive: "#343C8E",
  },
  ["#0DA789"]: {
    primaryBtnActive: "#09745F",
  },
  ["#11B8B"]: {
    primaryBtnActive: "#0D8F94",
  },
  ["#F1C231"]: {
    primaryBtnActive: "#BF9002",
  },
  ["#D93F22"]: {
    primaryBtnActive: "#A9311A",
  },
  ["#DD3972"]: {
    primaryBtnActive: "#82153B",
  },
  ["#674EA7"]: {
    primaryBtnActive: "#351C75",
  },
  ["#000000"]: {
    primaryBtnActive: "#434343",
  },
  ["#999999"]: {
    primaryBtnActive: "#666666",
  },
};

export { themeColor };
