import React, { useState, useEffect, useMemo } from "react";
import "../index.scss";
import * as Survey from "../Contexts/SurveyContext";
import Questionnaire from "../Components/Questionnaire";
import FinishPage from "./FinishPage";
import VerifyPage from "./VerifyPage";
import * as Ant from "antd"; //wiki加入IE判斷
import { Color } from "../Components/Widget"; //wiki加入IE判斷
import { ExclamationCircleFilled } from "@ant-design/icons"; //wiki加入IE判斷

// 2022-08-09 ALVIN 增加Slate
import { withReact, Slate } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import RichTextEditor from "../Components/RichTextEditor";

import WelcomePage from "./WelcomePage";
import ReactGA from "react-ga4";
import _ from "lodash";
import { SURVEY_STATUS, SURVEY_VALID_REGISTER } from "../constants";

const RichTextUtils = require("../Utils/RichTextUtils");

const queryString = require("query-string");
let windowGlobal = typeof window !== "undefined" && window;

/**
 * 問卷填寫頁面
 * Review 2024/11/06
 * 問卷預覽/樣板/外部站台填寫的進入點
 * 1. 確認Token ： [GET] /api/OutsideSurvey/Login
 * 2. 決定呈現頁面：[GET] /api/OutsideSurvey/RenderSurvey 的response決定呈現的頁面
 * 2.1 正常的流程為
 *     [資格驗證頁面|surveyStatus=11+validRegister=2] -> [歡迎/個資同意頁面|surveyStatus=11] -> [問卷頁面|surveyStatus=2]
 * 2.2 錯誤的流程為
 *     [錯誤頁面|surveyStatus=1|3|4|5|others]
 * 2.3 surveyStatus狀態碼說明
 *     surveyStatus=1      : 問卷不存在
 *     surveyStatus=2      : 正常問卷 (2022-11-30後一進入不會有這個狀態了，強制都會11，因應中華電信個資管理規範，須請用戶勾選個資聲明才可填寫問卷。)
 *     surveyStatus=2      : 填寫登入資料頁面(符合資格才能填寫) validRegister = 2 || undefined 填寫個資
 *     surveyStatus=3      : 本問卷已截止
 *     surveyStatus=4      : 測試問卷已額滿
 *     surveyStatus=5      : 參數傳遞驗證失敗
 *     surveyStatus=11     : 個資同意
 *     surveyStatus=others : 請確定網路連結正確
 * @param {*} props
 * @returns
 */
function Page(props) {
  let isTestData = false;
  if (typeof window !== "undefined") {
    // query string 包含是否為測試站台
    isTestData = queryString.parse(window.location.search).test;
  }

  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [surveyStatus, setSurveyStatus] = useState(2);
  const [validRegister, setValidRegister] = useState();
  const [validField, setValidField] = useState(0);
  // 判斷是否通過資格驗證 for CDP資格驗證型問卷
  const [isValidationPassed, setIsValidationPassed] = useState(false);

  // 2022-08-09 ALVIN welcome page data
  const [welcomePageData, setWelcomePageData] = useState(null);
  const queryParameters = queryString.parse(props.location.search);
  /**
   * queryParameters
   * id: 問卷ID
   * test: true=測試填寫, others=正式填寫
   * ProvideType: 1=網頁收集, 2=QRCode收集
   * p: 導流參數
   * preview: 預覽
   * template: 樣板
   */
  const id = _.get(queryParameters, "id"); // id:問券ID
  // const surveyEnv = 2; //正式環境
  // const provideType = 1;
  // surveyEnv: 1=測試填寫, 2=正式填寫
  const surveyEnv = _.get(queryParameters, "test") === undefined ? 2 : 1; // 正式環境是 undefined 測試環境是1
  // provideType: 1=網頁收集, 2=QRCode收集
  const provideType = _.get(queryParameters, "ProvideType"); //數據來源 1:測試環境;2:正式環境
  // p: 導流參數
  const p =
    _.get(queryParameters, "p") === undefined
      ? ""
      : _.get(queryParameters, "p");
  // 預覽（感覺沒在使用）
  const ifPreview =
    _.get(queryParameters, "preview") === undefined ? false : true;
  // 樣板（感覺沒在使用）
  const ifTemplate =
    _.get(queryParameters, "template") === undefined ? false : true;
  const surveyAnswerStatus = _.get(queryParameters, "status");

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  //用IE瀏覽器開啟時，跳出的彈跳視窗 wiki
  function _confirmIE() {
    Ant.Modal.warning({
      title: "本問卷不支援 IE 瀏覽器",
      content: "為提供最佳操作體驗，請改用 Chrome 瀏覽器。",
      okText: "關閉",
      onOk: () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  useEffect(() => {
    ReactGA.initialize("G-WNJFKVQLF0");
    ReactGA.send("pageview");
    //判斷是否為IE瀏覽器 wiki
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      setLoading(false);
      _confirmIE();
      return;
    }

    /**
     * 判斷題目暫存
     * Review 2024-11-06
     * [重要] 此段為問卷預覽/樣板/外部站台填寫的進入點
     * ex: https://telesurvey.cht.com.tw/q?id={問卷ID|GUID}&ProvideType={1=正式填寫,2=測試填寫}&p={導流參數}
     * 1. 確認Token： /api/OutsideSurvey/Login
     * 2. 決定呈現頁面：/api/OutsideSurvey/RenderSurvey 的response決定呈現的頁面
     * 2.1 正常的流程為
     *     [資格驗證頁面|surveyStatus=11+validRegister=2] -> [歡迎/個資同意頁面|surveyStatus=11] -> [問卷頁面|surveyStatus=2]
     * 2.2 錯誤的流程為
     *     [錯誤頁面|surveyStatus=1|3|4|5|others]
     * 2.3 surveyStatus狀態碼說明
     *     surveyStatus=1      : 問卷不存在
     *     surveyStatus=2      : 正常問卷 (2022-11-30後一進入不會有這個狀態了，強制都會11，因應中華電信個資管理規範，須請用戶勾選個資聲明才可填寫問卷。)
     *     surveyStatus=2      : 填寫登入資料頁面(符合資格才能填寫) validRegister = 2 || undefined 填寫個資
     *     surveyStatus=3      : 本問卷已截止
     *     surveyStatus=4      : 測試問卷已額滿
     *     surveyStatus=5      : 參數傳遞驗證失敗
     *     surveyStatus=11     : 個資同意
     *     surveyStatus=others : 請確定網路連結正確
     *
     * 備註：此段使用大量if..else，且將可以先排除的錯誤頁面放在else if的最後面，應該改為先將錯誤頁面放在最前面判斷，後續再依序判斷正常頁面
     *      因開發時程因素，暫時先不大幅度調整以確保功能正常
     * @returns
     */
    async function getToken() {
      /**
       * 判斷是否可以呈現介面
       * 非預覽、非樣板、surveyAnswerStatus為undefined、provideType為undefined
       * 呈現問卷不存在
       */
      if (
        !ifPreview &&
        !ifTemplate &&
        surveyAnswerStatus === undefined &&
        provideType === undefined
      ) {
        //didn't have ProvieType 問卷不存在
        setSurveyStatus(1);
      } else {
        try {
          windowGlobal = typeof window !== "undefined" && window;
          windowGlobal.localStorage.setItem("SEnv", surveyEnv);

          if (!ifPreview && !ifTemplate) {
            // 取的 Token 由外層移置 if 之後
            const resp = await Survey.Actions.getExternalUserToken(
              id,
              surveyEnv,
              provideType,
              p
            );

            // 原本為  if (resp && !ifPreview && !ifTemplate)
            // 但因為 fetch token 移至 if 內層
            // 所以 resp 多拆判斷
            if (resp) {
              // hotfix: 如果為需要驗證密碼，則先清空token，不然重新測試會略過驗證頁面造成錯誤
              if (resp.ValidRegister === SURVEY_VALID_REGISTER.VALIDATE) {
                windowGlobal.localStorage.removeItem("token");
                Survey.Actions.setExternalLocalStorageToken(false);
              }
              // windowGlobal.localStorage.setItem("token", resp.Token);
              // Survey.Actions.setExternalLocalStorageToken(true);
              if (resp.ValidRegister === 1 || resp.ValidRegister === 3) {
                try {
                  windowGlobal.localStorage.setItem("token", resp.Token);
                  Survey.Actions.setExternalLocalStorageToken(true);
                } catch (err) {
                  if (
                    err.name === "QUOTA_EXCEEDED_ERR" ||
                    err.name === "QuotaExceededError"
                  ) {
                    alert(
                      "您的瀏覽器暫存區容量已滿，目前無法儲存答題內容，請手動清除瀏覽紀錄或改用無痕瀏覽器填寫問卷。"
                    );
                  }
                }
              }
              setValidField(resp.ValidField);
              setSurveyStatus(resp.SurveyStatus);
              setValidRegister(resp.ValidRegister);
              setAccessToken(resp.Token);

              // 2022-08-09 ALVIN 歡迎API
              // 狀態如果是 11 則有歡迎頁面
              /**
               * CDP資格驗證型問卷
               * 2022年後一定會顯示歡迎頁面，但是遇到資格驗證時 accessToken並不會儲存於 windowGlobal.localStorage.getItem("token")
               * 所以在這邊直接指定access token
               */
              if (resp.SurveyStatus === 11) {
                // 顯示歡迎頁
                let externalData = await Survey.Actions.fetchExternalSurvey(
                  id,
                  true,
                  resp.Token
                );
                if (externalData.code === "200") {
                  if (!externalData.welcomePage.WelcomePageStyle) {
                    setLoading(false);
                    return;
                  }
                  console.log("Welcome page returned", externalData);
                  setWelcomePageData(externalData.welcomePage);
                }
              }
            }
          } else {
            setSurveyStatus(1);
          }
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
      setLoading(false);
    }
    getToken();
  }, []);

  const renderPage = props => {
    if (loading) {
      return (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            zIndex: "999999",
          }}
        >
          <Ant.Spin />
        </div>
      );
    }
    // TODO:拉為參數
    // surveyStatus
    // 1 問卷不存在
    // 2 正常問卷
    // 2 填寫登入資料頁面(符合資格才能填寫) validRegister = 2 || undefined 填寫個資
    // 3 本問卷已截止
    // 4 測試問卷已額滿
    // 5 參數傳遞驗證失敗
    // 11 個資同意
    // others 請確定網路連結正確
    /**
     * validRegister
     * 1=匿名填寫
     * 2=資格驗證（CDP只有行動電話or其他文字格式）
     * 3=參數傳遞 (CDP不用特別傳參數，只要透過自訂渠道發放，都會對應到CDP的聯絡人ID)
     */

    let img = "/images/EndPage_Web.png";
    let buttonText = "關閉視窗";

    // 預覽頁面
    if (ifPreview) {
      return (
        <Questionnaire externalLink={false} ifTemplate={false} {...props} />
      );
    }
    // 樣板預覽頁面：提早呈現
    if (ifTemplate) {
      return (
        <Questionnaire externalLink={false} ifTemplate={true} {...props} />
      );
    } else {
      if (surveyStatus === 1) {
        //未開放
        return (
          <FinishPage
            isTestData={isTestData}
            title="問卷不存在"
            topImg="/images/EndPage_Web.png"
            surveyStatus={surveyStatus}
          />
        );
      } else if (
        surveyStatus === 2 &&
        (validRegister === 1 ||
          validRegister === 3 ||
          isValidationPassed === true)
      ) {
        return (
          <Questionnaire
            externalLink={true}
            ifTemplate={false}
            provideType={provideType}
            passByParameter={p}
            {...props}
          />
        );
      } else if (
        // 強制加上歡迎頁面之後，就不會有2的狀態了
        isValidationPassed === false &&
        (surveyStatus === SURVEY_STATUS.AVAILABLE ||
          surveyStatus === SURVEY_STATUS.SHOW_WELCOME_PRIVACY) &&
        (validRegister === SURVEY_VALID_REGISTER.VALIDATE ||
          validRegister === undefined)
      ) {
        // 填寫登入資料頁面
        // 2024-11-06 新增驗證成功的callback function作為parameter
        return (
          <VerifyPage
            title="填寫登入資料頁面"
            surveyId={id}
            validField={validField}
            provideType={provideType}
            accessToken={accessToken}
            onValidSuccess={() => {
              setSurveyStatus(11);
              setIsValidationPassed(true);
            }}
            {...props}
          />
        );
      } else if (surveyStatus === 3) {
        //已截止 已額滿 測試問卷已截止
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="本問卷已截止"
            surveyStatus={surveyStatus}
          />
        );
      } else if (surveyStatus === 4 || surveyStatus === 6) {
        //已截止 已額滿 測試問卷已額滿
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="本問卷已額滿"
          />
        );
      } else if (surveyStatus === 5) {
        //參數傳遞驗證失敗
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("verifyInfo");
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="發生錯誤, 請重新使用連結開啟"
          />
        );
      } else if (surveyStatus === 11) {
        console.log("props", props);
        // 進行個資同意
        let text;

        const initialWelcomePage = {
          WelcomePageStyle:
            '[{"type":"align-left","children":[{"type":"paragraph","children":[{"text":"您好！感謝您參與此問卷調查，為瞭解客戶意見以提供更好服務，誠摯邀請您參加線上調查。您所填寫的資料我們僅會用於整體統計分析，不會揭露個人資訊，敬請安心填答。","size":"20","bold":true,"color":"#324250"}]}]}]',
          WelcomePagePic: "/images/EndPage_Web.png",
          ButtonSentence: "前往問卷",
        };

        if (props.hasOwnProperty("WelcomePageStyle")) {
          text = welcomePageData?.WelcomePageStyle;
          text = RichTextUtils.catText(text);
          img = welcomePageData?.WelcomePagePic;
          buttonText = welcomePageData?.ButtonSentence;
        } else {
          text = initialWelcomePage.WelcomePageStyle;
          img = initialWelcomePage.WelcomerPagePic;
          buttonText = initialWelcomePage.ButtonSentence;
        }

        return (
          <WelcomePage
            surveyId={id}
            afterSubmit={() => setSurveyStatus(2)}
            welcomePageData={welcomePageData}
          >
            {welcomePageData?.WelcomePageStyle ? (
              <Slate
                editor={editor}
                value={JSON.parse(welcomePageData.WelcomePageStyle)}
              >
                <RichTextEditor readOnly={true} />
              </Slate>
            ) : (
              <Slate editor={editor} value={JSON.parse(text)}>
                <RichTextEditor readOnly={true} />
              </Slate>
            )}
          </WelcomePage>
        );
      } else {
        Survey.Actions.setExternalLocalStorageToken(false);
        return (
          <FinishPage
            isTestData={isTestData}
            topImg={img}
            btnText={buttonText}
            title="請確定網路連結正確"
          />
        );
      }
    }
  };

  return <Survey.Provider>{renderPage(props)}</Survey.Provider>;
}

export default Page;
